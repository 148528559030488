/**
 * @author : ritesh.dabral
 * This page is responsible for handling all reducers, combining them and creating a single store
 * You can add middlewares to the store, as per requirements
 * Follow simplicity and proper folder structure
 * 
 */

// import reducers
 import {hotspotReducer} from './Reducers/HotspotEditReducer/HotspotEditReducer'
 import {view360Reducer} from './Reducers/View360Reducer/View360Reducer'
 import {homePageReducer} from './Reducers/HomePageReducer/HomePageReducer'

import { createStore, combineReducers } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension';

// all reducers go inside root reducer

const rootReducer = combineReducers({
    hotspotReducer,
    view360Reducer,
    homePageReducer
});

// Global Store
const reduxStore = createStore(rootReducer);


// export
export default reduxStore;