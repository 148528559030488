export const IMAGE_COMPRESSOR = (props) => {
    const { url, resolution, filterFormate, isOriginal = false } = props;
    let compressBaseUrl = process.env.REACT_APP_PUBLIC_IMAGE_COMPRESSOR_BASE_URL
    if (resolution) {
        compressBaseUrl += `/${resolution}`
    }
    if (filterFormate) {
        compressBaseUrl += `/filters:format(${filterFormate})`
    }
if (!filterFormate) {
        compressBaseUrl += `/filters:format(webp)`
    }
    if(isOriginal){
        return url
    }
    return `${compressBaseUrl}/${url}`
    // return url
}