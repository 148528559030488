export const transformedSku360Data = (apiData) => {
  const _360data = {
    skuDetails:[], //360> image_data
    skuId:"",  //sku_id
    isVdp:false,  //is_vdp
    interior360Data:[], //360> interior_360
   video_urls:[]  //>360 video_data 
 }
  if (!apiData) {
    return _360data
  }

  const data360 = apiData["360"] || {};

  return {
    skuDetails: data360.image_data || [], 
    skuId: apiData.sku_id || "", 
    isVdp: apiData.is_vdp, 
    interior360Data: data360.interior_360 || [], 
    video_urls: data360.video_data || [] 
  };
};