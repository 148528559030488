import React from 'react'
import Styles from '../../../Css/View360/View360.module.css'

const MobileViewHeadingContainer = ({beforAfterView,video360Urls}) => {
    return (
        <div className={Styles["mobile-video-uploader"]}>

            {beforAfterView ?
                <div className={Styles['slider-container']}>
                    <div className={Styles['image-slider']}>

                        <div className={Styles['image-slider-header']}>
                            <div className={Styles["mobile-input-output-container"]}>
                                <p className={Styles['before-after-text']}>Before</p>
                            </div>
                        </div>
                    </div>
                </div> : null}
            <div className={Styles["before-image"]}>
                <div className={Styles['beforeImg']}>
                    <div className={Styles['video-container']}>
                        <video autoPlay muted playsInline loop controls src={video360Urls[0]?.lres_video_url || video360Urls[0]?.video_url} type="video/mp4" className={Styles['frame_video']}>
                        </video>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MobileViewHeadingContainer
