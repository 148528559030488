import React from 'react'
import Styles from '../../../Css/View360/View360.module.css'
import View360CommonStyle from '../../../Css/View360/View360Common.module.css'
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { checkIsV2 } from '../../../utils/getAppVersion';
const theme = createTheme({
    components: {
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#ccc"
                },
                colorPrimary: {
                    "&.Mui-checked": {
                        // Controls checked color for the thumb
                        color: "#f2ff00"
                    }
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 1,
                    backgroundColor: "#fff",
                    ".Mui-checked.Mui-checked + &": {
                        // Controls checked color for the track
                        opacity: 1,
                        backgroundColor: "#A7A7A7"
                    }
                }
            }
        }
    }
});
const HotspotToggleHandler = ({handleHotSpotViewToggle,toggleHotspotView}) => {
    const isV2 = checkIsV2()
    return (
        <div className={isV2 ? View360CommonStyle['hotspot-info-tag-left']  : [Styles['hotspot-info-tag-left']]}>
            Hotspots
            <ThemeProvider theme={theme}>
                <Switch
                    onChange={handleHotSpotViewToggle}
                    checked={toggleHotspotView}

                    sx={{
                        '& .MuiSwitch-thumb': {
                            backgroundColor: toggleHotspotView ? (isV2 ? 'rgba(70, 0, 242, 1)' : '#fff') : (isV2 ? '#fff' : '#B1B1B1') // Change thumb color when switch is on
                        },
                        '& .MuiSwitch-track': {
                            backgroundColor: isV2 ? 'rgba(0, 0, 0, 0.12)' : '#707070' // Change track color
                        },
                        '& .css-19ock7p-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                            backgroundColor: '#FFFFFF33'
                        }
                    }}
                    className={[Styles["customSwitchTrack"]]}
                />
            </ThemeProvider>

        </div>
    )
}

export default HotspotToggleHandler
