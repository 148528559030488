import {handleGetRequest} from './handleGetReq';
import { handlePostRequest } from './handlePostReq';
import { handleDeleteRequest } from './handleDeleteReq';

const centralAPI = {
    handleGetRequest,
    handlePostRequest,
    handleDeleteRequest
}


export default centralAPI;