import React, { memo } from 'react'
import Styles from '../../../Css/View360/View360.module.css'

const BeforeAfterHeading = ({showhotspotBeforeAfter}) => {
  return (
    <div className={Styles['slider-container']}>
    <div className={Styles['image-slider']}>

        <div className={Styles['image-slider-header']}>
            <div className={Styles[showhotspotBeforeAfter ? "grid-view" : "min-list-view"]}>
                <p className={Styles['before-after-text']}>Before</p>
            </div>
            <div className={Styles[showhotspotBeforeAfter ? "grid-view" : "max-list-view"]}>
                <p className={Styles['before-after-text']}>After</p>
            </div>
        </div>
    </div>
</div>
  )
}

export default memo(BeforeAfterHeading)
