import React, { useState } from 'react';

const Tabs = ({ options, onSelect,customStyle }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };


  return (
    <div className={`w-full font-medium text-center text-black z-50 ${customStyle && customStyle}`}>
      <ul className={`flex items-center justify-around text-nowrap gap-2.5 p-2 sm:p-0 md:p-2`}>
        {options?.length > 0 && options.map((tabItem) => (
          <button className={`w-full flex py-3 sm:px-4 items-center rounded-xl sm:rounded-none  md:rounded-xl bg-white sm:bg-transparent md:bg-white justify-center gap-2 text-xs sm:text-base font-medium cursor-pointer shadow-black-8 sm:shadow-none md:shadow-black-8  ${selectedOption.label === tabItem.label ? ` ${customStyle ? "border-none" : "sm:border-blue-activeBlue"} sm:border-b-[3px] bg-blue-activeTabBg sm:bg-transparent md:bg-blue-activeTabBg ${customStyle ? "text-white-default !font-bold" : "text-blue-activeBlue" }  md:border-none` : 'border-none bg-white-default sm:bg-transparent md:bg-white-default shadow-black-8'} ${tabItem.isDisabled ? "pointer-events-none text-gray-500" :`${customStyle ? "text-white-70 font-medium" : "text-black-60" } `}  `}
            key={tabItem?.label}
            onClick={() => handleSelect(tabItem)}>
              {tabItem?.icon && (selectedOption.label === tabItem.label ? <img className='h-5 w-5' src={tabItem.activeIcon} alt={tabItem?.label}/> : <img className='h-5 w-5' src={tabItem.icon} alt={tabItem?.label}/>)}
              {tabItem?.label}
            {tabItem?.showItemsLength && <span className={`max-sm:hidden rounded-full text-sm ${selectedOption.label === tabItem.label ? "bg-blue-15 text-blue-activeBlue" :"bg-black-80 text-white-default"} px-2`}>{tabItem.totalItem}</span> }
          </button>
        ))}


      </ul>
    </div>

  )
};


export default Tabs