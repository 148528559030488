import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from './pages/AppLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div className="App h-screen">
            <ToastContainer />
      <BrowserRouter>
        <Routes>
            <Route path="/360" element={<AppLayout/>} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
