import React from 'react';
import Switch from '@mui/material/Switch';
import Styles from '../../../Css/View360/View360.module.css';

const HotspotVisibilityComponent = ({ handlerMobileHotspot, setHandlerMobileHotspot, toggleHotspotVisibility }) => {
  const handleToggleChange = () => {
    setHandlerMobileHotspot(prev => !prev);
    toggleHotspotVisibility();
  };

  return (
    <div className={Styles['hotspot-info-tag-left-mobile-view']}>
      Hotspots
      <Switch
        onChange={handleToggleChange}
        checked={handlerMobileHotspot}
        sx={{
          '& .MuiSwitch-thumb': {
            backgroundColor: '#4600F2',
          },
        }}
        className={Styles["customSwitchTrack"]}
      />
    </div>
  );
};

export default HotspotVisibilityComponent;