import React, { Component } from 'react'
import Styles from '../../Css/View360/View360.module.css'
export class View360FallbackUI extends Component {

    constructor(props){
        super(props);

        this.state = {
            errStatus: 500
        }
    }

    componentDidMount(){
        if ( this.props.errStatus ){
            this.setState({...this.state, errStatus: this.props.errStatus})
        }
    }

    handleDashboardRedirection = (e)=>{
        try {
            e.stopPropagation();
            let newURL = `${process.env.REACT_APP_APP_DASHBOARD_PROJ_PREFIX}/dashboard/login`;
            window.location.href = newURL;
        } catch (error) {
            //console.log(error);
        }
    }
    render() {
        return (
            <div className={[Styles['fallback-ui-container']].join(' ')}>
                {
                    (this.state.errStatus === 404) ? (<span>404 Page Not Found</span>) : <span>500 Server Error</span>
                }
                <img src={`${process.env.REACT_APP_CLOUD_STORAGE_PREFIX}/404/view360-fallback.png`} alt="" />
                {/* {
                    (this.state.errStatus === 404) ?  <button style={{marginTop: '10px'}} onClick={this.handleDashboardRedirection}>Click to go back to dashboard</button> : <span>Something Went Wrong</span>
                } */}
            </div>
        )
    }
}

export default View360FallbackUI
