import React from 'react'
import View360 from "../Components/View360/View360"
import { checkIsV2 } from '../utils/getAppVersion';
import CommonIframeHome from './CommonIframeHome';

const AppLayout = () => {
    const isV2 = checkIsV2()

  return (
    <>
   {isV2 ? <CommonIframeHome/>  : <View360 />}
    </>
  )
}

export default AppLayout