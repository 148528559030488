import ReactGA4 from 'react-ga4';
import * as amplitude from '@amplitude/analytics-browser';

    const getDomain = ()=>{
        let currentDomain='';
        if (typeof window !== 'undefined') {
            currentDomain = window?.location?.ancestorOrigins?.[0] ? window.location.ancestorOrigins[0] : window?.location?.hostname;
        }
        return currentDomain;
    }
    const initializeGA4 = ()=>{
        const domain = getDomain();
        ReactGA4.initialize([
            {
            trackingId: process.env.REACT_APP_GTM_CONTAINER_ID,
            gaOptions: {
                cookieFlags: 'SameSite=None;Secure', // Ensures compatibility with third-party cookies in an iframe
            },
            },
        ], {
            linker: {
            domains: [domain] // Specify both your domain and the third-party domain
            }
        });


    }
    initializeGA4();
    export const captureEvents = async ({ event, custom = {}}) => {
        try {
            const domain = getDomain();
            ReactGA4.event(event,  {
                ...custom,
                domain: domain || '',
            });
            // amplitude.track(event , {
                //     ...custom
                // })
            } catch (error) {
                console.warn(error);
            }
        }
        
        
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
    export const amplitudeEvents = async ({event , customAmplitudeEvents = {}}) => {

        try{
            amplitude.track(event , {
                ...customAmplitudeEvents,
            })
        }
        catch (error) {
            console.warn(error);
        }
    }
