/**
 * 
 * This page contains reducers
 * 
 */

 import {
    UPDATE_CLICKED_IMAGE_URL,
    UPDATE_IMAGE_FILE_DATA
 } from './HomePageActionType'

 // Initial state
 const initialState = {
    file:{},
    tryImageData:{imageURL:"",subCategoryId: "", index: 0}
 }



 /**
  * 
  * @param {*} state : current state, by default initialState
  * @param {*} action : object with properties [type, payload, info]
  * 
  * return: updated state based on action type
  */
 export const homePageReducer = (state=initialState, action)=>{

    switch(action.type){

        case UPDATE_CLICKED_IMAGE_URL :{
            const {imageURL,subCategoryId, index,sampleImagesForCurrCategory,pageName} = action.payload
            return {
            ...state,
            tryImageData: {"imageURL": imageURL, "subCategoryId": subCategoryId, "index": index,"sampleImagesForCurrCategory":sampleImagesForCurrCategory ,"pageName": pageName}
        }} 

        case UPDATE_IMAGE_FILE_DATA : return {
            ...state,
            file: action.payload
        }

        default : return state
    }
 }

