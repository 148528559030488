import React from 'react'
import Styles from '../../../Css/View360/View360.module.css'
import View360FallbackUI from '../View360FallbackUI'

const FallbackUrl = ({ isRequestFor3DModel, errStatus }) => {
    return (
        isRequestFor3DModel ?
            <div className={[Styles['fallback-ui-container'], Styles['please-wait']].join(' ')}>
                Your 3d model is being generated, kindly <br /> check back after few minutes
            </div> : <View360FallbackUI errStatus={errStatus} />
    )
}

export default FallbackUrl
