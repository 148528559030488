import React from 'react'
import Styles from '../../../Css/View360/View360.module.css'

const MobileViewAfterContainer = () => {
  return (
      <div className={Styles['slider-container']}>
          <div className={Styles['image-slider']}>

              <div className={Styles['image-slider-header']}>
                  <div className={Styles["mobile-input-output-container"]}>
                      <p className={Styles['before-after-text']}>After</p>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default MobileViewAfterContainer
