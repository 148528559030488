import { iframeTabs, TAB_ENUM, TAB_OPTIONS } from "../Components/common/config";

export const getDynamicTab = (apiData) => {
  return TAB_OPTIONS.filter(({ key }) => {
    const config = apiData?.team_config[key];
    const hasContent =
      (key === TAB_ENUM.THREE_SIXTY_VIEW && apiData?.['360']?.image_data?.length > 0 ) ||
      (key === TAB_ENUM.VIDEO_VIEW && apiData?.video?.length > 0) ||
      (key === TAB_ENUM.GALLERY_VIEW && Object.values(apiData?.image || {}).some(imagesArray => imagesArray.length > 0));

    return config?.isActive && hasContent;
  }).sort((a, b) => {
    const positionA = apiData?.team_config[a.key]?.position ?? 0;
    const positionB = apiData?.team_config[b.key]?.position ?? 0;
    return positionA - positionB;
  });
};

export const getDynamicTabImage = (apiData) => {
  const filteredTabs = iframeTabs.filter((tab) => {
    const normalizedLabel = tab.label.toLowerCase();
    return Object.keys(apiData).some((key) => key.includes(normalizedLabel));
  });

  // Determine if there's additional data not covered by "Exterior" or "Interior"
  const hasAdditionalData = Object.keys(apiData).some((key) => {
    const lowerKey = key.toLowerCase();
    return !lowerKey.includes("exterior") && !lowerKey.includes("interior");
  });

  // If there is additional data, ensure the "Additional" tab is included
  if (hasAdditionalData) {
    const additionalTab = iframeTabs.find(tab => tab.label === "Additional");
    if (additionalTab) {
      filteredTabs.push(additionalTab);
    }
  }

  return filteredTabs;
};
