export const singleData = {
    iframeTabs: [
        { title: 'Exterior', key: 'EXT' },
        { title: 'Interior', key: 'INT' },
        {
            title: 'Underneath', key: 'UNDERNEATH'
        }
    ],

    beforeAfterTabs:[
        { title: 'Spin 360 + Hotspots', key: 'hotspot', },
        { title: 'Spin 360 only', key: 'not_hotspot' }
    ]
}