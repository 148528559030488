/**
 * 
 * This page contains reducers
 * 
 */

 import {
    UPDATE_CURRENT_IMAGE,
    UPDATE_CURRENT_IMAGE_REF,
    ADD_HOTSPOT,
    UPDATE_HOTSPOT,
    DELETE_HOTSPOT, 
    //INCREASE_HOTSPOT_COUNT,
    UPDATE_CURRENT_HOTSPOT_ID,
    UPDATE_CURRENT_HOTSPOT_OBJ,
    UPDATE_WHOLE_HOTSPOT_MAP_OBJECT,
    SYNC_HOTSPOT_TITLE_DESC_URL,
    UPDATE_WHOLE_SYNC_HOTSPOT_TITLE_DESC_URL,
    UPDATE_PROJECTID_AND_SKUID
 } from './HotspotEditActionType'

 // Initial state
 const initialState = {
    currentImageObj: {},
    currentImageRefObj: {},
    hotSpotMapObj: new Map(), //map to store imagehotspot json,
    hotSpotCount: 0,
    hotSpotId: '',
    currentHotSpotObj : {}, // stores current hotspot object to be added everywhere,
    syncHotSpotTitleDescURL : new Map(),
    projectIdAndSkuId : {
        projectId: '',
        skuId: ''
    }
 }


/********* IMAGE HOTSPOT JSON ***********/
//  {
//     imgId : '',
//     isHidden: false, //in case image or frame was deleted
//     imageHotSpots: [ // map of hotspots
//         {
//             hotSpotId: '', //skuId_imgId_seqNo,
//             hotSpotIconSrc: '',
//             coordinates: {
//                 xAxis: 0,
//                 yAxis: 0
//             },
//             timeStamp: {
//                 createdOn:'',
//                 updatedOn:''
//             },
//             hotSpotDesc:'',
//             hotSpotTitle:'',
//         }
//     ]

// }


 /**
  * 
  * @param {*} state : current state, by default initialState
  * @param {*} action : object with properties [type, payload, info]
  * 
  * return: updated state based on action type
  */
 export const hotspotReducer = (state=initialState, action)=>{

    switch(action.type){

        case UPDATE_CURRENT_IMAGE: return {
            ...state,
            currentImageObj : action.payload
        }

        case UPDATE_CURRENT_IMAGE_REF: return {
            ...state,
            currentImageRefObj : action.payload
        }

        case ADD_HOTSPOT: {

            // add logic here
            let newState = {...state};

            // update hotSpotMapObj
            
            let {newHotspot,imageId} = action.payload;
            
            // check if imageid exist
            if(newState.hotSpotMapObj.has(`${imageId}`)) {
                // add to the map of obj
                let tempImgData = newState.hotSpotMapObj.get(`${imageId}`);
                // push into map of tempImgData
                tempImgData.imageHotSpots.set(`${newHotspot.hotSpotId}`, newHotspot);
            }else{
                // create new key: imageId and value as below JSON
                let imageHotSpots = new Map();
                imageHotSpots.set(`${newHotspot.hotSpotId}`, newHotspot);
                newState.hotSpotMapObj.set(`${imageId}`, {
                    isHidden: false, //in case image or frame was deleted
                    imageHotSpots: imageHotSpots
                })
            }


            return newState;
        }

        case UPDATE_HOTSPOT:  {

            let newState = {...state};

            // update hotSpotMapObj
            
            let {updatedHotspot,imageId} = action.payload;

            // imageId exists for update scenario

            newState.hotSpotMapObj.get(`${imageId}`).imageHotSpots
                .set(`${updatedHotspot.hotSpotId}`, updatedHotspot);

            return newState
        }

        case DELETE_HOTSPOT: {

            let newState = {...state};

            // update hotSpotMapObj
            
            let {oldHotspotId,imageId} = action.payload;

            // imageId exists for update scenario

            //console.log('imageId',imageId)
            //console.log(newState.hotSpotMapObj);

            newState.hotSpotMapObj.get(`${imageId}`).imageHotSpots.delete(oldHotspotId);

            return newState
            
        }

        // case INCREASE_HOTSPOT_COUNT: return {
        //     ...state,
        //     hotSpotCount : action.payload + 1
        // }

        case UPDATE_CURRENT_HOTSPOT_ID: return {
            ...state,
            hotSpotId: action.payload
        }

        case UPDATE_CURRENT_HOTSPOT_OBJ : return {
            ...state,
            currentHotSpotObj : action.payload
        }

        case UPDATE_WHOLE_HOTSPOT_MAP_OBJECT : return {
            ...state,
            hotSpotMapObj: new Map(action.payload)
        }

        case SYNC_HOTSPOT_TITLE_DESC_URL : {

            const {hotSpotId, titleDescURL} = action.payload;

            let newState = {...state};

            if( newState.syncHotSpotTitleDescURL.has(hotSpotId) ){

                // check if title is present or not, or maybe different
                let {title, desc, url} = titleDescURL;

                // if title is empty leave it
                // if title has value
                //  check if syncHotSpotTitleDescURL.get(hotSpotId).title  = title

                let newSyncHotSpotTitleDescURL = newState.syncHotSpotTitleDescURL.get(hotSpotId);

                if(title) newSyncHotSpotTitleDescURL.title = title;
                if(desc) newSyncHotSpotTitleDescURL.desc = desc;
                if(url) newSyncHotSpotTitleDescURL.url = url;

                newState.syncHotSpotTitleDescURL.set(hotSpotId, newSyncHotSpotTitleDescURL);
            }else{
                newState.syncHotSpotTitleDescURL.set(hotSpotId, {...titleDescURL});
            }

            return newState;
        }

        case UPDATE_WHOLE_SYNC_HOTSPOT_TITLE_DESC_URL : return {
            ...state,
            syncHotSpotTitleDescURL: new Map(action.payload)
        }

        case UPDATE_PROJECTID_AND_SKUID : {

            const newState = {...state};

            const {projectId,skuId} = action.payload;

            newState.projectIdAndSkuId = {
                projectId: projectId,
                skuId: skuId
            }

            return newState;
        }

        default : return state
    }
 }

