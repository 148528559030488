import axios from "axios";

export const handlePostRequest = (URL, callBody)=>{
    let handlePostPromise = new Promise((resolve, reject)=>{
        axios.post(URL,callBody).then(res=>{
            if(res.data?.status === 200)
                resolve(res.data);
            else if(res.status === 200)
                resolve(res.data)
            else 
                reject(res.data['message'] || res.data);
            
        }).catch(err=>{
            reject(err);
        })
    });

    return handlePostPromise;

}


