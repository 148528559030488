/**
 * @author: ritesh.dabral
 * 
 * This page includes constants for action types
 */

export const UPDATE_CURRENT_IMAGE = 'UPDATE_CURRENT_IMAGE';

export const UPDATE_CURRENT_IMAGE_REF = 'UPDATE_CURRENT_IMAGE_REF';

export const ADD_HOTSPOT = 'ADD_HOTSPOT';

export const UPDATE_HOTSPOT = 'UPDATE_HOTSPOT';

export const DELETE_HOTSPOT = 'DELETE_HOTSPOT';

//export const INCREASE_HOTSPOT_COUNT = 'INCREASE_HOTSPOT_COUNT';

export const UPDATE_CURRENT_HOTSPOT_ID = 'UPDATE_CURRENT_HOTSPOT_ID';

export const UPDATE_CURRENT_HOTSPOT_OBJ = 'UPDATE_CURRENT_HOTSPOT_OBJ';

export const UPDATE_WHOLE_HOTSPOT_MAP_OBJECT = 'UPDATE_WHOLE_HOTSPOT_MAP_OBJECT';

export const SYNC_HOTSPOT_TITLE_DESC_URL = 'SYNC_HOTSPOT_TITLE_DESC_URL';

export const UPDATE_WHOLE_SYNC_HOTSPOT_TITLE_DESC_URL = 'UPDATE_WHOLE_SYNC_HOTSPOT_TITLE_DESC_URL';

export const UPDATE_PROJECTID_AND_SKUID = 'UPDATE_PROJECTID_AND_SKUID';