import React, { memo, useEffect, useState } from 'react'
import { amplitudeEvents } from '../../../utils/utilityFunctions';

const PreFetchImages = ({images,showHotspot,imageLoaded, imagesLoadHandler, captureEvent , skuId , skuName , enterpriseId}) => {
    const [eventCaptured, setEventCaptured] = useState(false);
    const [queueList,setQueueList] = useState({
        queue1 : [],
        queue2 : [],
        queue3 : [],
        queue4 : []
    })
    function callbackQueue(img_id,queue){
        try {
            setQueueList((prevQueue)=>({
                ...prevQueue,
                [queue] : [...prevQueue[queue],img_id]
            }))
        } catch (error) {
            console.log(error)
        }
    }
    const batch1 = images.filter((num, index) => (index + 1) % 8 === 0);
    const batch2 = images.filter((num, index) => (index + 1) % 4 === 0 && (index + 1) % 8 !== 0);
    const batch3 = images.filter((num, index) => (index + 1) % 2 === 0 && (index + 1) % 4 !== 0);
    const batch4 = images.filter((num, index) => (index + 1) % 2 !== 0);

    useEffect(()=>{
        if(queueList.queue4.length==0)return;
        showHotspot();
        // SEND POST IMAGE FOR IMAGES LOADED
        if(batch4.length >0){
            imagesLoadHandler();
        }
        if(!eventCaptured && batch4?.length>0 && queueList?.queue4?.length>0 && batch4?.length===queueList?.queue4?.length && typeof captureEvent === 'function'){
            captureEvent('all_images_loaded');
            const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
            const time = offsetDate?.toISOString()?.slice(0, -1);
            amplitudeEvents({
                event: 'all_images_loaded',
                customAmplitudeEvents: {
                    sku_id: skuId,
                    enterprise_id: enterpriseId,
                    skuName: skuName,
                    time: time
                }
            })
            setEventCaptured(true);
        }

    },[queueList.queue4, batch4, eventCaptured])
    function failedCallback(img_id,queue){
        try {
            setQueueList((prevQueue)=>({
                ...prevQueue,
                [queue] : [...prevQueue[queue],img_id]
            }))
        }catch (error) {

        }
    }
   
    
    if(!imageLoaded)return;
    return (
        <>
       { batch1?.length ? batch1.map((img, index) => {
             return <ImageContainer queue="queue1" img={img} index={index} callback={callbackQueue} failedCallback={failedCallback}/>
        } ) : null}
        {(batch2?.length && batch1?.length === queueList.queue1.length) ? batch2.map((img, index) => {
            return <ImageContainer queue="queue2" img={img} index={index} callback={callbackQueue} failedCallback={failedCallback}/>
       } ) : null}
        {batch3?.length && ((batch1?.length + batch2?.length) === (queueList.queue1.length + queueList.queue2.length))? batch3.map((img, index) => {
            return <ImageContainer queue="queue3" img={img} index={index} callback={callbackQueue} failedCallback={failedCallback}/>
       } ) : null}

        {batch4?.length && (batch1?.length + batch2?.length + batch3.length) === ((queueList.queue1.length + queueList.queue2.length + queueList.queue3.length)) ? batch4.map((img, index) => {
            return <ImageContainer queue="queue4" img={img} index={index} callback={callbackQueue} failedCallback={failedCallback}/>
       } ) : null}
       </>

    )
}


const ImageContainer=({img,index,callback,failedCallback,queue})=>{
    return(
        <img key={index} src={img.src} onLoad={()=>callback(img.imageId,queue)} onError={()=>failedCallback(img.imageId,queue)} alt="" style={{ display: 'none', height: '10px', width: '10px' }} />
    )
}
export default memo(PreFetchImages)