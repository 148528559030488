import React, { PureComponent} from 'react'
import centralAPI from '../../CentralAPIHandler/CentralAPIHandler';
import View360HelperText from './View360HelperText';
import Styles from '../../Css/View360/View360.module.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Switch from "react-switch";
import 'animate.css';
import View360Slider from './View360Slider'
import Loader from '../common/Loader';
// redux
import {connect} from 'react-redux'
import {updateWholeHotSpotMapObj} from '../../Redux/Reducers/View360Reducer/View360ActionController'
import ToggleButton from './ToggleButton';
import MobileViewSlider from './MobileViewSlider';
import InteriorFrameDropdown from './InteriorFrameDropdown';
import InteriorIframe from './InteriorIframe';
import { captureEvents , amplitudeEvents } from '../../utils/utilityFunctions';
import PreFetchImages from './PreFetchImages/PreFetchImages';
import BeforeAfterHeading from './BeforeAfterContainer/BeforeAfterHeading';
import BeforeContainer from './BeforeAfterContainer/BeforeContainer';
import HotspotToggleHandler from './HotspotToggleHandler/HotspotToggleHandler';
import MobileToggleHandler from './HotspotToggleHandler/MobileToggleHandler';
import MobileViewHeadingContainer from './BeforeAfterContainer/MobileViewHeadingContainer';
import MobileViewAfterContainer from './BeforeAfterContainer/MobileViewAfterContainer';
import FallbackUrl from './FallbackUrl/FallbackUrl';
import UnderneathIframe from './UnderneathIframe';
import UnderneathFrameDropdown from './UnderneathFrameDropdown';

class View360 extends PureComponent {


    constructor(props){
        super(props);
        this.elementRef = React.createRef();
        this.state = {
            images: [],
            automatedImageSpin: true,
            clockwise: false,
            angleOrderIncreasing: true,
            imageIndex : 0,
            noOfImg: 0,
            oldx : 0,
            showDownloadBtn : true,
            loading: true,
            output_video_url: '',
            imgId: '',
            isEngagementStarted: false,
            engagementTimeout: null,
            progressCount: 0,
            downloadProgress: 0,
            isDownloading: false,
            enterpriseid_for_download : '',
            skuId_for_download: '',
            hotspotImageId: '',
            hotSpotsToMap: [],
            isIntTabActive: false,
            ToggleInteriorHotspots: false,
            hotspotDataInterior: [],
            dragSpeed: 5,
            hotSpotModal: {
                show: false,
            },

           //Mock Interior Hotspot
           interiorData: [
            {
                    
                hotspots: [
                            {
                                hotspot_id: "6d5da2028f844ecc996704fe32ab8659",
                                type: "tag1",
                                reasons: ["Reason1"],
                                hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                focus_image_url: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                coordinates: { x: 0, y: 0 , z:0 }
                            },
                            {
                                hotspot_id: "8da5b950a2274e769aa7696c346ca436",
                                type: "tag2",
                                reasons: ["Reason2"],
                                hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                focus_image_url: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                coordinates: { x: 2, y: 2 , z:2 }
                            },
                            {
                                hotspot_id: "8da5b950a2274e769aa7696c346ca436",
                                type: "tag4",
                                reasons: ["Reason4"],
                                hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                focus_image_url: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                coordinates: { x: -4, y: 19 , z:3 }
                            }
                        ]
                    },
                ],
            

            freshLoad: true,
            toggleHotspotView: false,
            imageNewDimensions : {
                isNewDimensionsUpdated : false,
                isPadByWidth: false,
                newImgWidth: 1,
                newImgHeight: 1,
                newContainerWidth:  1,
                newContainerHeight:  1,
                paddingHalf: 0
            },
            modalList: new Map(),
            errStatus : {
                code: 500, // handle errors
                show: false
            },
            sessionId: `session_${Math.random().toString(36).substring(2, 11)}`,
            sessionStartTime: null,
            goToSlide: 0, // open this slide on modal,
            lazyLoadImages: false,
            renderHotSpots: false,
            isAnyHotSpotPresent: false,
            isRequestFor3DModel: false,
            isInput360Requested: false,
            isZoomActive: false,
            isFunctionLoaded : false , 
            currentZoomLevel: 100,
            isToggleEnableJson : false,
            showHelperText: true,
            skuName: '',
            showExtOrInt : 'EXT',
            isLoaded : false,
            interior360Url : [],
            showValue : {},
            animationEnd : {},
            zoomValue : 0,
            skuId: null,
            selectedHotspotObjKey : null,
            isDownloadEnabled : false,
            activeHotspot : null,
            isAnyFocusImage : true,
            hotspotActiveValue : null,
            imageLoaded : false,
            notMoveImageOnHover: false,
            spinMotion : null,
            beforAfterView : false,
            showhotspotBeforeAfter : false,
            showInteriorHotspot: false,
            activeToggleBeforeAfter : 'hotspot',
            video360Urls : [],
            showMinWidthWebView : null,
            selectedUrl : { 'name' : '' ,'url' : '' , 'id' : '' },
            selectedUnderneathUrl : { 'name' : '' ,'url' : '' },
            showDropdown : false,
            IsVdp: false,
            timeStart: null,
            timeEnd: null,
            userStats: {
                rotationStats: {
                    percentageFramesVisited: 0,
                    visitedIndexes: new Map(),
                    mouseFramesCount: 0
                },
                hotspotStats:{
                    clickCount: {},
                    viewedFullScreenModal: false
                },
                timeStats: {
                    fetchImagesAPITime: 0,
                    hotspotDataAPITime: 0,
                    firstImageLoadTime: 0,
                    userRetentionTime: 0
                }
            },
            showInteriorFrame : true,
            showHotspotWhenImagesLoaded : false
        }

        this.handleHotSpotViewToggle = this.handleHotSpotViewToggle.bind(this);
        

        this.zoomInBtn = React.createRef();
        this.zoomOutBtn = React.createRef()
        this.resetBtn = React.createRef();
        this.transformRef = React.createRef();
    }
    componentDidMount = async () => {
        
        const queryParams = new URLSearchParams(window?.location?.search);
        window?.parent?.postMessage(
          JSON.stringify({
            type: "IFRAME_LOADED",
            value: queryParams?.get("sku_id"),
          }),
          "*"
        );
       
       
        await this.startSession();
        let timeStart = new Date().getTime();
        this.setState({
            ...this.state,
            timeStart: timeStart
        })

        // track window resize, and update NewImageDimensions
        window.addEventListener('resize', this.updateNewImageDimensions);
        // window.addEventListener('beforeunload', this.handleBeforeUnload);
        window.addEventListener('beforeunload', this.closeSession);
        window.addEventListener('unload', this.closeSession);


        if(this.props && this.props.dragSpeed){
            this.setState({
                ...this.state,
                dragSpeed: this.props.dragSpeed
            })
        }
        window.addEventListener('message', this.handleMessage);
        const {imageCategory, type} = this.getSkuIdAndImageCategory();
        if( imageCategory && imageCategory==='3D_Model'){
            this.setState({...this.state, 'isRequestFor3DModel': true})
        }else if(type && type==='input'){
            this.setState({...this.state, 'isInput360Requested': true , showDownloadBtn:false })
        }
        await this.fetchImages();
                document.addEventListener('keydown', this.handleKeyDown); 

              
    }

    componentWillUnmount() {
        document.addEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('resize', this.updateNewImageDimensions);
        window.removeEventListener('message', this.handleMessage);
        // window.removeEventListener('beforeunload', this.handleBeforeUnload);
        window.removeEventListener('beforeunload', this.closeSession);
        window.removeEventListener('unload', this.closeSession);

        this.closeSession();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.beforAfterView !== this.state.beforAfterView && this.state.beforAfterView && this.state.IsVdp) {
            this.setState({ automatedImageSpin: false });
        }
        if (prevState.automatedImageSpin !== this.state.automatedImageSpin) {
            if(this.state.automatedImageSpin){
              this.handleImageSpin();
          }
        }
        if(prevState.showExtOrInt !== this.state.showExtOrInt){
            if(this.state.showExtOrInt === 'INT'){
                this.setState({...this.state, toggleHotspotView : true,ToggleInteriorHotspots: false , showDownloadBtn: false })
    
               
            }else if(this.state.showExtOrInt==='EXT') 
                            this.setState({ showDownloadBtn: true });

        }
      
            




        // if (this.state.showExtOrInt === 'INT'){
        //     this.setState({ showDownloadBtn: false });
        //   }
        // else {
        //     this.setState({ showDownloadBtn: true });
        // }



        // if(prevState.toggleHotspotView !== this.state.toggleHotspotView){
        //     this.setState({
        //         ...this.state, ToggleInteriorHotspots: !this.state.toggleHotspotView
        //     })
        // }
        if(prevState.angleOrderIncreasing !== this.state.angleOrderIncreasing){
            //if angles are in decreasing order
            if(!this.state.angleOrderIncreasing){
                this.setState({...this.state, images: this.state.images.reverse()})
            }
        }
    }

    apiCallWrapper = async (skuId, value) => {
        try {
            const {skuName,enterpriseId} = this.getSkuIdAndImageCategory();
            this.setState({skuName: skuName});
            const event_key= value==='image_list'? 'start_json1': 'start_json2';
            const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
            const time = offsetDate?.toISOString()?.slice(0, -1);
            this.captureEvent(event_key);
            const URL= (value==='image_list' && skuName)? `https://dx58rqsf48le3.cloudfront.net/Spinjson/${enterpriseId}/${skuName}/${value}.json` : `https://dx58rqsf48le3.cloudfront.net/Spinjson/${skuId}/${value}.json`;
            let response = await centralAPI.handleGetRequest(URL);
            amplitudeEvents({
                event: event_key,
                customAmplitudeEvents: {
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download,
                    time: time
                }
            })
            return response;

            
        } 
        
        
        catch (error) {
            return {}; 
        }
        
    };


    capturejsonResponseEndEvent =(value, failed=false)=>{
        try {
            const endTime= new Date().toISOString();
            const {skuId , skuName} = this.getSkuIdAndImageCategory();
            const key = 'image_list'? 'end_json1': 'end_json2'
            captureEvents({
                event: value==='image_list'? 'end_json1': 'end_json2', 
                custom: {
                    time: endTime,
                    res_failed: !!failed,
                    iframe_session_id: this.state.sessionId,
                    sku_id: skuId || this.state.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download,
                }
            }            
            );

            amplitudeEvents({
                event: value==='image_list'? 'end_json1': 'end_json2', 
                customAmplitudeEvents: {
                    time: endTime,
                    res_failed: !!failed,
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download,
                    skuName: skuName
                }
            })
        } catch (error) {
            
        }
    }

    
    captureEvent = (event)=>{
        try {
            const startTime= new Date().toISOString();
            const {skuId , skuName} = this.getSkuIdAndImageCategory();

            captureEvents({
                event,
                custom: {
                time: startTime,
                iframe_session_id: this.state.sessionId,
                sku_id: skuId || this.state.skuId_for_download ,
                enterprise_id: this.state.enterpriseid_for_download
                }
            })
        } catch (error) {
            
        }
        
    }



   

    startSession = async  ()=>{
        try {
            const startTime = new Date().toISOString();    
            this.setState({ sessionStartTime: startTime });
            const {skuId , skuName} = this.getSkuIdAndImageCategory();
    
            captureEvents({
                event: 'iframe_page_view',
                custom: {
                    time: startTime,
                    iframe_session_id: this.state.sessionId,
                    sku_id: skuId || this.state.skuId_for_download,
                    enterprise_id: this.state.enterpriseid_for_download
                }
            })

            amplitudeEvents({
                event: 'iframe_page_view',
                customAmplitudeEvents: {
                    time: startTime,
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download, 
                    skuName: skuName
                }
            })
        } catch (error) {
        
        }
       
    }
    closeSession = (event) =>{
       try {
        const endTime = new Date().toISOString();
        const {skuId , skuName} = this.getSkuIdAndImageCategory();
        const duration = Math.floor((new Date(endTime) - new Date(this.state.sessionStartTime)) / 1000);

         captureEvents({
            event: 'iframe_close',
            custom: {
                time: endTime,
                iframe_session_id: this.state?.sessionId,
                sku_id: skuId || this.state?.skuId_for_download,
                enterprise_id: this.state?.enterpriseid_for_download,
                duration: duration + '  Seconds', // Duration in seconds

            }
        })
      


        amplitudeEvents({
            event: 'iframe_close',
            customAmplitudeEvents: {
                time: endTime,
                iframe_session_id: this.state?.sessionId,
                sku_id: skuId || this.state?.skuId_for_download,
                enterprise_id: this.state?.enterpriseid_for_download,
                duration: duration, // Duration in seconds
                skuName: skuName

            }
        })

        if(event)
         event.returnValue = ''; // some browsers require setting returnValue to show the dialog
       } catch (error) {
        
       }
    }

    updateButtonState = () => {
        if (this.state.showExtOrInt === 'INT') {
          this.setState({ showDownloadBtn: true });
        } else {
          this.setState({ showDownloadBtn: false });
        }
      };

      
    // handleBeforeUnload = (event) => {
    // let clickCountJSON = JSON.stringify(this.state.userStats.hotspotStats.clickCount);
    // captureEvents({event: 'iFrame user statistics', custom: {
    // percentageFramesVisited: this.state.userStats.rotationStats.percentageFramesVisited,
    // mouseFramesCount: this.state.userStats.rotationStats.mouseFramesCount,
    // clickCount: clickCountJSON,
    // userRetentionTime: (new Date().getTime() - this.state.timeStart)/1000,
    // }
    // });
    // // Standard way to show a confirmation dialog
    // event.returnValue = ''; // some browsers require setting returnValue to show the dialog
    // }
   
    checkAngleDirection(images){
        try {
          const angles = new Set(images.map((image) => image.angle));
          const uniqueAngles = Array.from(angles);
          if(uniqueAngles.length < 3 ) return "Increasing";
          let increasingCnt = 0;
          let decreasingCnt = 0;
          for (let i = uniqueAngles.length - 1; i > 0; i--) {
            if (uniqueAngles[i] > uniqueAngles[i - 1]) {
              if (decreasingCnt > 0) decreasingCnt = 0;
              increasingCnt++;
              if (increasingCnt > 1) {
                return "Increasing";
              }
            } else {
              if (increasingCnt > 0) increasingCnt = 0;
              decreasingCnt++;
              if (decreasingCnt > 1) {
                return "Decreasing";
              }
            }
          }
        } catch (error) {}
        }

    getImageResolution=(imageUrl)=> {
        try {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl;
    
            img.onload = function() {
              const width = img.width;
              const height = img.height;
              resolve({ width, height });
            };
    
            img.onerror = function() {
              reject(new Error("Failed to load the image."));
            };
          });
        } catch (error) {
            console.log(error)
        }
      }

    getSkuIdAndImageCategory = ()=>{
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const skuId = queryParams.get('sku_id');
            const imageCategory = queryParams.get('image_category');
            let type = queryParams.get('type')
            let hotspot_val = queryParams.get('hotspot')?JSON.parse(queryParams.get('hotspot')):true
            let skuName = queryParams.get('sku_name')
            let enterpriseId = queryParams.get('enterprise_id')
            let spinMotion = queryParams?.get('spin_motion') ? JSON.parse(queryParams.get('spin_motion')) : false
            let beforAfterView = queryParams?.get('input')
            let webView = queryParams?.get('web_view') ? JSON.parse(queryParams.get('web_view')) : false
            let showInteriorFrame = queryParams?.get('enable_int_frame') ? JSON.parse(queryParams.get('enable_int_frame')) : true
            if (!type) type = 'output'
            hotspot_val = !(webView || !hotspot_val)

            return { skuId, imageCategory, type, skuName, enterpriseId, hotspot_val, spinMotion,beforAfterView,showInteriorFrame}
        } catch (error) {
            //console.log(error);
        }
    }
    /**
     * Fetch images and hotspots from db
     * @returns 
     */
    fetchImages = async()=>{
        try {
            const {skuId, imageCategory, type,skuName,enterpriseId,hotspot_val,spinMotion,beforAfterView,showInteriorFrame} = this.getSkuIdAndImageCategory();
            const images=[];
            const allImageInObject = {}
            
            if(!skuId && !skuName) return;
            // console.log("Hotspot values " , hotspot_val)

            // const URL = `${process.env.REACT_APP_IMAGECREW_NODE_PROJ_PREFIX}/api/v1/hotspot/get-sku-image-data`
            const URL = `https://prod-360-api.tech-dc2.workers.dev`;
            var params = {}

            if(skuName && enterpriseId){
                params = {
                    'imageCategory' : imageCategory,
                    'type': type,
                    'skuName' : skuName,
                    'enterpriseId' : enterpriseId
                }
            }else{
                params = {
                    'skuId': skuId,
                    'imageCategory' : imageCategory,
                    'type': type
                }
            }

            let fetchImagesStartTime = new Date().getTime();
            // const { skuDetails,interior360Data,video_urls } = await centralAPI.handleGetRequest(URL, params);
       
            // this.updateStats({fetchImagesAPITime});

            // console.log(params)let skuDetails
                        let skuDetails
            let interior360Data
            let underneathData
            let response
            let video_urls = []
            let IsVdp
            let rotationDirection
            let enterprise_id_download
            let sku_id_download
            // this.setState({...this.state, IsVdp: isVdp, skuId: skuId })
            // let cachePresent = sessionStorage?.getItem(`360`) ? JSON.parse(sessionStorage.getItem(`360`))?.[skuId] : null
            // if(cachePresent && type == 'output'){
            //     skuDetails = cachePresent?.skuDetails
            //     video_urls = cachePresent?.video_urls
            //     interior360Data = cachePresent?.interior360Data
            //     IsVdp = cachePresent?.isVdp
            // }else{
                let skuApiStartTime = new Date();
                let isJson = false;

                response = await this.apiCallWrapper(skuId, 'image_list');
                let detect_hotspot = response?.detectHotspot;
                let jsonResponseStatus = response;
                
                if (!response?.skuDetails?.length) {
                    this.captureEvent('start_api1')
                    const startTime= new Date().toISOString();
                    const {skuId , skuName} = this.getSkuIdAndImageCategory();
                    amplitudeEvents({
                        event:'start_api1',
                        customAmplitudeEvents: (
                            {   
                                time: startTime,
                                iframe_session_id: this.state?.sessionId,
                                sku_id: skuId || this.state?.skuId_for_download ,
                                enterprise_id: this.state?.enterpriseid_for_download
                            }
                        )
                    })
                    response = await centralAPI.handleGetRequest(URL, params);
                    this.captureEvent('end_api1')
                    amplitudeEvents({
                        event:'end_api1',
                        customAmplitudeEvents: (
                            {   
                                time: startTime,
                                iframe_session_id: this.state?.sessionId,
                                sku_id: skuId || this.state?.skuId_for_download ,
                                enterprise_id: this.state?.enterpriseid_for_download
                            }
                        )
                    })
                    isJson = false;
                } else {
                    isJson = true;
                    if(detect_hotspot) {
                        this.setState({isToggleEnableJson: true})
                    }
                }
                this.capturejsonResponseEndEvent('image_list', !isJson);




                // captureEvents({event: 'sku_api_successful', custom: {
                //         'time_taken': skuApiEndTime - skuApiStartTime,
                //         // 'json_data': JSON.stringify(response)
                // }})   
                skuDetails = response?.skuDetails
                interior360Data = response?.interior360Data.filter(data => !data?.tags?.overlay_name?.includes('Underneath'))
                underneathData = response?.interior360Data.filter(data => data?.tags?.overlay_name?.includes('Underneath'));
                video_urls = response?.video_urls
                IsVdp = response?.isVdp
                rotationDirection = response?.sequenceData?.rotation;
                enterprise_id_download = response?.skuDetails[0].enterprise_id;
                sku_id_download = response?.skuId
                this.setState({enterpriseid_for_download: enterprise_id_download})
                this.setState({skuId_for_download : sku_id_download})

                if(response?.interior360Data.length)
                {
                    amplitudeEvents({
                        event: 'interior_present',
                        customAmplitudeEvents: {
                            iframe_session_id: this.state?.sessionId,
                            sku_id: skuId || this.state?.skuId_for_download ,
                            enterprise_id: this.state?.enterpriseid_for_download,
                            skuName: skuName,
                            }
                    })
                }


                // sessionStorage.setItem(`${skuId}`, JSON.stringify(response))
                // type==='output' && sessionStorage.setItem(`360`, JSON.stringify({[skuId] : response})) 
            // }
            let fetchImagesEndTime = new Date().getTime();
            let fetchImagesAPITime = (fetchImagesEndTime-fetchImagesStartTime)/1000;            
            if(!skuDetails || !skuDetails.length) throw Error('Fetch req successful, still skuDetails came out as null or empty array');
            if(IsVdp && imageCategory==='360_exterior' ){
                const exteriorURL = `${process.env.REACT_APP_IMAGECREW_NODE_PROJ_PREFIX}/spin/impel-exterior-image-data`
                let params = {}
                if(skuName && enterpriseId){
                params = {
                    'skuName' : skuName,
                    'enterpriseId' : enterpriseId
                }
                }else{
                    params = {
                    'skuId': skuId,
                }}

                const exteriorResponse = await centralAPI.handleGetRequest(exteriorURL, params);
                skuDetails= exteriorResponse?.skuDetails
                    
            }

            let result;
            if(window?.innerWidth < 440 && !spinMotion){
                let response =await this.getImageResolution(skuDetails[0]?.['output_image_hres_url'])
                const {width,height} = response
                result = this.calculateAspectRatio(width, height)

            }
            const allImageIDs = [];
            let imageCategoryForJson = '3D_Model'
            if(isJson) imageCategoryForJson =  this.checkCategory(skuDetails)
            skuDetails.forEach(image=>{
                if(isJson && imageCategoryForJson ==='3D_Model' && image['image_category'] ==='360_original')return;
                let originalUrl = image['output_image_hres_url']
                let inputUrl = 'https://media.spyneai.com/unsafe/filters:format(webp)/' + image['input_image_hres_url']

                if(spinMotion){
                    originalUrl = `https://media.spyneai.com/unsafe/350x196/filters:format(webp)/` + originalUrl
                }else if(window?.innerWidth < 500){
                    originalUrl = `https://media.spyneai.com/unsafe/${window?.innerWidth}x${Math.round((window.innerWidth/(result?.simplifiedWidth || 16))*(result?.simplifiedHeight || 9))}/filters:format(webp)/` + originalUrl
                }
                else{
                    originalUrl = 'https://media.spyneai.com/unsafe/filters:format(webp)/' + originalUrl
                }
                // originalUrl = 'https://media.spyneai.com/unsafe/375x211/filters:format(webp)/' + originalUrl
                // mobileUrl = 'https://media.spyneai.com/unsafe/375x211/filters:format(webp)/' + mobileUrl

                // console.log(originalUrl)
                allImageInObject[image['image_id']] = { 
                    src: originalUrl,
                    angle: image['AI_ANGLE'],
                    imageId: image['image_id'],
                    imageName: image['image_name']
                }
                images.push({
                    src: (isJson && type == 'input')? inputUrl : originalUrl,
                    angle: image['AI_ANGLE'],
                    imageId: image['image_id'],
                    imageName: image['image_name']
                });

                allImageIDs.push(image['image_id']);
            });
            let angleDirection= this.checkAngleDirection(images);

            this.setState((prev) => ({
                ...prev,
                images: images,
                angleOrderIncreasing: (angleDirection==="Increasing")? true : false,
                noOfImg: images.length || 0,
                imgId: images?.[0]?.imageId || '',
                loading: false,
                lazyLoadImages: true,
                toggleHotspotView: hotspot_val,
                spinMotion: spinMotion ? true : false,
                beforAfterView: beforAfterView ? true : false,
                video360Urls: video_urls,
                showMinWidthWebView: !hotspot_val,
                IsVdp: IsVdp,
                clockwise: Boolean(rotationDirection === 'clockwise') ? true : false,
                skuId, skuId,
                // interiorData: [
                //     {
                //         hotspots: [
                //             {
                //                 hotspot_id: "hotspot1",
                //                 type: "Infotainment Screen",
                //                 reasons: ["Reason1"],
                //                 hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                //                 focus_image_url: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_dddec78c-c8fb-4a76-8b2d-f739b6412aee.png",
                //                 coordinates: { x: 5, y: -5 , z: 0 }
                //             },
                //             {
                //                 hotspot_id: "hotspot2",
                //                 type: "AC Vent",
                //                 reasons: ["Reason2"],
                //                 hotspot_icon: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_85914232-b90c-46a4-bc54-78b99f556705.png",
                //                 focus_image_url: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_36b3362c-ee5f-4fea-a996-3c808b815f87.png",
                //                 coordinates: { x: 40, y: -15 , z: 0 }
                //             },
                //             {
                //                 hotspot_id: "hotspot3",
                //                 type: "Steering wheel",
                //                 reasons: ["Reason4"],
                //                 hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                //                 focus_image_url: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_502fb716-eb27-478b-aeb6-4b410a6adbae.png",
                //                 coordinates: { x: -40, y: -15 , z: 0 }
                //             },
                //             {
                //                 hotspot_id: "hotspot4",
                //                 type: "Gear Lever",
                //                 reasons: ["Reason4"],
                //                 hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                //                 focus_image_url: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_f33c2f96-9071-4c54-ba45-fc22c6c6a1de.png",
                //                 coordinates: { x: -5, y: -35 , z: 0 }
                //             }
                //         ]
                //     },
                // ]
            }));
            
            let hotSpotMapURL = `${process.env.REACT_APP_IMAGECREW_NODE_PROJ_PREFIX}/api/v1/hotspot/get-hotspots`;
            let hotspotApiStartTime = new Date();
            let mapping;
            let isHotspotJson = false;
            
            if (detect_hotspot) {
            mapping = await this.apiCallWrapper(sku_id_download, 'hotspot_list');
            this.state.isAnyHotSpotPresent = true;
            isHotspotJson = !!(mapping?.hotspots?.length);
            this.capturejsonResponseEndEvent('hotspot_list', !isHotspotJson);

            }
            
            if(this.state?.isAnyHotSpotPresent) {
                const { skuName, skuId } = this.getSkuIdAndImageCategory();
                amplitudeEvents({
                    event: 'hotspots_present',
                    customAmplitudeEvents: {
                        iframe_session_id: this.state?.sessionId,
                        sku_id: skuId || this.state?.skuId_for_download,
                        enterprise_id: this.state?.enterpriseid_for_download,
                        skuName: skuName,
                    }
                })
            }

            if (detect_hotspot == false || !isJson || !mapping?.hotspots?.length) {
                this.captureEvent('start_api2');
                amplitudeEvents({
                    event: 'start_api2',
                    customAmplitudeEvents: {
                        iframe_session_id: this.state?.sessionId,
                        sku_id: skuId || this.state?.skuId_for_download,
                        enterprise_id: this.state?.enterpriseid_for_download,
                        skuName: skuName,
                    }
                })

            mapping = await centralAPI.handleGetRequest(hotSpotMapURL, {
            skuId: sku_id_download,
            });
            this.captureEvent('end_api2');
            amplitudeEvents({
                event: 'end_api2',
                customAmplitudeEvents: {
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download,
                    skuName: skuName,
                }
            })
            this.state.isAnyHotSpotPresent = true;

            if(mapping?.hotspots?.length)
                {
                    const { skuName, skuId } = this.getSkuIdAndImageCategory();
                    amplitudeEvents({
                        event: 'hotspots_present',
                        customAmplitudeEvents: {
                            iframe_session_id: this.state?.sessionId,
                            sku_id: skuId || this.state?.skuId_for_download,
                            enterprise_id: this.state?.enterpriseid_for_download,
                            skuName: skuName,
                        }
                    })
                }
            }

            const newHotspotData = mapping?.hotspots?.[0]?.interior_tagged_images?.[0]?.hotspots;
            const hotspotImage = mapping?.hotspots?.[0]?.interior_tagged_images?.[0]?.image_id;
            
            if (newHotspotData) {
            this.setState({ hotspotDataInterior: newHotspotData, hotspotImageId: hotspotImage }, () => {
            });
            } else {
            console.log('No data available for hotspots');
            }

            
            let hotspotApiEndTime = new Date();
            // captureEvents({event: 'hotspot_api_successful', custom: {
            //     'time_taken': hotspotApiEndTime - hotspotApiStartTime
            // }})   
            // let mapping = {
            //     'hotspots' : []
            // }
            if(mapping)
                this.setHotSpotMapObjInStore(mapping, images, type === 'input', interior360Data, underneathData, allImageInObject, hotspot_val, spinMotion,beforAfterView,video_urls,showInteriorFrame);
        } catch (error) {
            console.log(error)
            if(error && error.message && error.message.match(/404/gmi)){
                this.setState({...this.state, loading: false, errStatus: {code: 404, show: true}})
            }
            //console.log(error);
        }


    };




// Interior Hotspot
async fetchData() {
    try {
        // Using mock data instead of API call
        let mapping = this.state.IntriorMockhotspots;

        if (mapping) {
            this.setHotSpotMapObjInStore(mapping, this.state.images, this.state.type === 'input',this.state.allImageInObject, this.state.hotspot_val, this.state.spinMotion, this.state.beforAfterView,  this.state.showInteriorFrame, this.state.cachePresent);
        }
    } catch (error) {
        console.log(error);
        if (error && error.message && error.message.match(/404/gmi)) {
            this.setState({ ...this.state, loading: false, errStatus: { code: 404, show: true } });
        }
    }
}















handleImagesLoaded = () => {
    try {
        if(!this.state.beforAfterView){
            window.parent.postMessage('allImagesLoaded', '*');
        }

       } catch (error) {
   }
}


handleIframeLoad =()=>{
    if(this.state.beforAfterView){
    this.setState({...this.state, automatedImageSpin: true, 
        // imageIndex : 0,
        // imgId: this.state.images[0]?.imageId,
});
}
}

handleIframeKeyDown = (event) => {
    const {key, code, keyCode, which, location, ctrlKey, shiftKey, altKey, metaKey, repeat, isComposing, bubbles, cancelable} = event.data;
    const keyboardEvent = new KeyboardEvent('keydown', {
        key,
        code,
        keyCode,
        which,
        location,
        ctrlKey,
        shiftKey,
        altKey,
        metaKey,
        repeat,
        isComposing,
        bubbles,
        cancelable,
      });
      this.handleKeyDown(keyboardEvent, true);
}
handleIframeMouseDown = () => {
    try {
        this.setState({...this.state,hotSpotModal:{ show: false}, automatedImageSpin: false, });
    } catch (error) {
        
    }
}
handleIframeMouseUp = (e) => {
    try {
       this.handleMouseUp(e, true)
    } catch (error) {
        
    }
}
handleIframeMouseMove = (type, x, y, buttons) => {
    try {
        //create new mouse event for iframe
        const event = new MouseEvent(type, {
            clientX: x,
            clientY: y,
            bubbles: true,
            cancelable: true,
            buttons: buttons,
          });
        this.move(event, true);
    } catch (error) {
        
    }
}

 handleMessage = (event) => {
    if (event.data === "allImagesLoaded") {
      this.handleIframeLoad();
    } else if(event.data === 'INT' || event.data === 'EXT' || event.data==='UNDERNEATH'){
        this.setState({
            ...this.state, 
            showExtOrInt: event.data,
            isLoaded: false,
            isZoomActive: false,
            currentZoomLevel: 100,
        })
    }
    else {
      const { type, clientX, clientY, height, width, buttons } = event.data;
      const docWidth = document.documentElement.clientWidth;
      const docHeight = document.documentElement.clientHeight;

      //calculate ratios
      const xRatio = docWidth / width;
      const yRatio = docHeight / height;

      const adjustedX = clientX * xRatio;
      const adjustedY = clientY * yRatio;

      switch (type) {
        case "mouse_down":
          this.handleIframeMouseDown();
          break;
        case "mouse_up":
          this.handleIframeMouseUp(event);
          break;
        case "mouse_move":
          this.handleIframeMouseMove(type, adjustedX, adjustedY, buttons);
          break;
        case "key_down":
          this.handleIframeKeyDown(event)
          break;
        default:
          break;
      }
    }
}


    gcd=(a, b)=>{
        try {
            return b === 0 ? a : this.gcd(b, a % b);
        } catch (error) {
            
        }
    }


    
    // Function to calculate aspect ratio and simplify it
     calculateAspectRatio=(width, height)=> {
        try {
            const aspectRatio = width / height;
            const divisor = this.gcd(width, height);
            const simplifiedWidth = width / divisor;
            const simplifiedHeight = height / divisor;
            return {
                aspectRatio: aspectRatio,
                simplifiedWidth: simplifiedWidth,
                simplifiedHeight: simplifiedHeight
            };
        } catch (error) {
            console.log(error)
        }
    }
    
    /**
     * Create hotspot mapping, if isHidden is false
     * and hotSpotMap
     * @param {*} mapping 
     */
    setHotSpotMapObjInStore = (mapping, images, isInputRequested = false, interior360Data, underneathData, allImageInObject, hotspot_val, spinMotion,beforAfterView=false,video_urls,showInteriorFrame) => {
        try {
            //console.log(mapping.hotspots);

            const newHotSpotMapObj = new Map();

            const newModalList = new Map() // {title, desc, url, closeUpURL, hotSpotId}
            /**
             * imageId use as map key, if isHidden is false
             * 
             * if the hotSpots array contains elements, put them under this imageId,
             * else leave as it is
             * 
             */
            let isAnyHotSpotPresent = false;
            // console.log(mapping )
            // debugger
            let isAnyFocusImage = true
            mapping?.hotspots[0]?.exterior_tagged_images?.forEach((element)=>{
                // debugger
                const {image_id, hotspots} = element;
                // console.log("This is element" , element)
                if(!allImageInObject[image_id])return;
                const newImageHotSpots = new Map();
                // console.log("This is mapping of newImage HotSpots" , newImageHotSpots);
                // if(!isHidden){    
                hotspots.forEach(hotSpotObj=>{
                    if(hotSpotObj?.focus_image_url)isAnyFocusImage = true // if any image has zoom in view it would be visible
                    // mark 'isAnyHotSpotPresent' true if hot spot present
                    if(isInputRequested){
                        // here we are setting input coordinates as coordinates to let the flow work same as it works for output
                        hotSpotObj['coordinates'] = {
                            "x": hotSpotObj?.coordinates?.x,
                            "y": hotSpotObj?.coordinates?.y
                        }
                    }

                    isAnyHotSpotPresent = true;
                    newImageHotSpots.set(hotSpotObj.hotspot_id, {...hotSpotObj}); // state to set hotspot
                    // set modal-list, if this id is not there in newModalList
                    if(!newModalList.has(hotSpotObj.hotspot_id)){
                        newModalList.set(hotSpotObj.hotspot_id, {
                            title: hotSpotObj.type || '',
                            desc: hotSpotObj.reasons?.[0] || '',
                            url: hotSpotObj.hotspot_icon || '',
                            closeUpURL: hotSpotObj?.focus_image_url ? `https://media.spyneai.com/unsafe/filters:format(webp)/${hotSpotObj?.focus_image_url}` : '',
                            image_id: image_id
                        });
                    }
                });

                // }
                newHotSpotMapObj.set(image_id, {isHidden : false,imageHotSpots:newImageHotSpots});
            });

            // console.log("This is modelList" , newModalList)
            // console.log("This is before newHotSpotMapObj" , newHotSpotMapObj); 
            // console.log("This is the updated" , updateWholeHotSpotMapObj);  
            this.props.updateWholeHotSpotMapObj(newHotSpotMapObj);

            // console.log("This is  after newHotSpotMapObj" , newHotSpotMapObj);

            this.setState((prevState)=>({
                ...prevState,
                images : [...images],
                noOfImg : images.length || 0,
                modalList: newModalList,
                isAnyHotSpotPresent: isAnyHotSpotPresent? hotspot_val : false,
                interior360Url : interior360Data,
                underneathData: underneathData,
                selectedUrl : interior360Data.length ? {name : interior360Data?.[0]?.image_name, url: interior360Data[0]?.output_image_hres_url?.length ? interior360Data[0]?.output_image_hres_url : interior360Data[0]?.input_image_hres_url,tags : interior360Data?.[0]?.tags , image_id: interior360Data?.[0]?.image_id } : { name : '' ,url : '' , id: '' },
                selectedUnderneathUrl: underneathData.length ? {name : underneathData?.[0]?.image_name,url:underneathData?.[0]?.input_image_hres_url,tags : underneathData?.[0]?.tags} : { name : '' ,url : '' },
                isAnyFocusImage : isAnyFocusImage,
                imgId : prevState?.imgId || '',
                // spinMotion : spinMotion ? true :false,
                beforAfterView : beforAfterView ? true : false,
                video360Urls : video_urls,
                // showMinWidthWebView : !hotspot_val,
                showInteriorFrame : showInteriorFrame
            }))

            // stop loading after 100ms
            // if(!cachePresent){
            //     setTimeout(()=>{
            //         this.setState({...this.state,loading: false,lazyLoadImages: true})
            //     },100)
            setTimeout(()=>{
                this.setState({...this.state,loading: false,lazyLoadImages: true})
                let imageLoadingTime = new Date().getTime();
                this.updateStats({imageLoadingTime});
                // captureEvents({event: 'first_image_load', custom: {
                //     'time_taken': imageLoadingTime - this.state.timeStart 
                // }})
              
    
            },100)  
        } catch (error) {
            //console.log(error);

            this.setState({
                ...this.state,
                loading: false,
                lazyLoadImages: true
            })
        }
    }


    setImageSrcInPlaceOfCloseUpURL = (imageIdToMatch, imagesList)=>{
        let imgSrc = '';
        try {

            for(let i=0;i<imagesList.length;i++){
                const {src,imageId} = imagesList[i];

                if(imageIdToMatch===imageId){
                    imgSrc = src;
                    break;
                }
            }

        } catch (error) {
            //console.log(error)
        }

        return imgSrc;
    }
   
    sendMessageToIframe = (type, event) => {
        try {
        const iframe = document.getElementById("iframe-vdp");
              if (type === "key_down") {
                const eventData = {
                  type: "key_down",
                  key: event.key,
                  code: event.code,
                  keyCode: event.keyCode,
                  which: event.which,
                  location: event.location,
                  ctrlKey: event.ctrlKey,
                  shiftKey: event.shiftKey,
                  altKey: event.altKey,
                  metaKey: event.metaKey,
                  repeat: event.repeat,
                  isComposing: event.isComposing,
                  bubbles: event.bubbles,
                  cancelable: event.cancelable,
                };

                if (iframe && iframe.contentWindow) {
                  iframe.contentWindow.postMessage(eventData, "*");
                } else {
                  window?.parent?.postMessage(eventData, "*");
                }
              } else if(type === 'EXT'){
                if (iframe && iframe.contentWindow) {
                    iframe.contentWindow.postMessage(type, "*");
                } 
              }else {
                const width = window.innerWidth;
                const height = window.innerHeight;
                const { clientX, clientY } = event;
            if (iframe && iframe.contentWindow) {
              const { clientX, clientY } = event;
                  iframe.contentWindow.postMessage(
                    {
                      type,
                      clientX,
                      clientY,
                      width,
                      height,
                    },
                    "*"
                  );
                } else {
                  window?.parent?.postMessage(
                    {
                      type,
                      clientX,
                      clientY,
                      width,
                      height,
                    },
                    "*"
                  );
                }
              }
        } catch (error) {}
    }

    /**
     * Mouse move handle
     * @param {*} e 
     */

    move = (e, calledFromMessage = false)=>{
        try {
            let framesAtOnce = 1;
            if(this.state.noOfImg>=100){
                framesAtOnce = 2;
            }
            if(this.state.noOfImg>=150){
                framesAtOnce = 3;
            }
            if(!this.state.imageNewDimensions.isNewDimensionsUpdated)
                this.updateNewImageDimensions();

            let oldIndex = this.state.imageIndex;
            // let visitedIndexes = [];
            let perImgSpeed=Math.floor(this.state.imageNewDimensions.newContainerWidth/(2*this.state.images.length));

            this.setState((prev)=>({...prev, renderHotSpots: !this.state.renderHotSpots, dragSpeed:perImgSpeed, automatedImageSpin: false}))

            const pageX = e.pageX ? e.pageX : e.changedTouches[0].pageX; // for touch changes
            let newIndex = null;
            if (pageX <= this.state.oldx-this.state.dragSpeed) {
                let right = ( parseInt(this.state.imageIndex) + 1 ) % parseInt(this.state.noOfImg);
                newIndex = right;
            } else if (pageX >= this.state.oldx+this.state.dragSpeed) {
                let left = (this.state.imageIndex - 1 < 0 )? (this.state.noOfImg-1) : (this.state.imageIndex-1);
                newIndex = left;
            }
            if(newIndex!==null && newIndex!==this.state.imageIndex){
                this.setState(prev=>({
                    ...prev,
                    imageIndex : newIndex,
                    oldx : pageX,
                    imgId: this.state.images[newIndex]?.imageId,
                    notMoveImageOnHover : true,
                }))
                this.updateStats({oldIndex, newIndex});
                // captureEvents({event: '360_spin_movement'})
                // captureEvents({event: 'frame_scrolled', custom: {
                //     'frame_number': [this.state.imageIndex]
                // }})
            }
            if(!calledFromMessage)
                this.sendMessageToIframe('mouse_move', e);


            //Amplitude events for the move

            if (!this.isEngagementStarted) {
                const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
                const overallTime = offsetDate?.toISOString()?.slice(0, -1);
                this.isEngagementStarted = true;
                this.engagementStartTime = new Date();
                const { skuName, skuId } = this.getSkuIdAndImageCategory();
                amplitudeEvents({
                    event: 'iframe_exterior_engage_started',
                    customAmplitudeEvents: {
                        iframe_session_id: this.state?.sessionId,
                        sku_id: skuId || this.state?.skuId_for_download,
                        enterprise_id: this.state?.enterpriseid_for_download,
                        skuName: skuName,
                        time: this.engagementStartTime,
                        dateTime:  overallTime
                    }
                });
            }
if (this.engagementTimeout) {
    clearTimeout(this.engagementTimeout);
}

this.engagementTimeout = setTimeout(() => {
    this.isEngagementStarted = false;

    const engagementEndTime = new Date();
    const engagementDurationMs = engagementEndTime - this.engagementStartTime;
    const engagementDurationSec = engagementDurationMs / 1000;
    const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
    const overallTime = offsetDate?.toISOString()?.slice(0, -1);
    

    const { skuName, skuId } = this.getSkuIdAndImageCategory();

    amplitudeEvents({
        event: 'iframe_exterior_engage_end',
        customAmplitudeEvents: {
            iframe_session_id: this.state?.sessionId,
            sku_id: skuId || this.state?.skuId_for_download,
            enterprise_id: this.state?.enterpriseid_for_download,
            skuName: skuName,
            engagementDuration: engagementDurationSec - 5  + '  Seconds' ,
            overallTime: overallTime
        }
    });

    this.engagementStartTime = null;
}, 5000); 

        } catch (error) {
            //console.log(error);
        }

    }

        /**
         * Mouse grab
         * @param {*} e 
         */
        handleMouseDown = (e)=>{
            // close modal if open
            this.setState({...this.state,hotSpotModal:{ show: false}, automatedImageSpin: false});
        e.target.addEventListener('mousemove', this.move);
        this.setState(showHelperText => ({showHelperText: false}));
                this.sendMessageToIframe('mouse_down', e);
        
        }


    /**
     * Mouse ungrab
     * @param {*} e 
     */
    handleMouseUp = (e, calledFromMessage = false)=>{
        e.target.removeEventListener('mousemove', this.move);
        if(!calledFromMessage)
            this.sendMessageToIframe('mouse_up', e);
    }

    handleKeyDown = (e, calledFromMessage = false) => {
        try{
            if(!this.state.imageNewDimensions.isNewDimensionsUpdated)
                this.updateNewImageDimensions();
            this.setState({...this.state, renderHotSpots: !this.state.renderHotSpots, dragSpeed:3, automatedImageSpin: false})

            let newIndex = null
            if (e.code === 'ArrowLeft') {
                // Navigate to the previous image
                let right = ( parseInt(this.state.imageIndex) + 1 ) % parseInt(this.state.noOfImg);
                newIndex = right;

            } else if (e.code === 'ArrowRight') {
                // Navigate to the next image
                let left = (this.state.imageIndex - 1 < 0 )? (this.state.noOfImg-1) : (this.state.imageIndex-1);
                newIndex = left;
            }else{
                let keyCombination = '';
                if (e.ctrlKey) keyCombination += 'Ctrl + ';
                if (e.shiftKey) keyCombination += 'Shift + ';
                keyCombination += e.key.toUpperCase();  
                if(e.ctrlKey || e.shiftKey){
                    window.parent.postMessage({ key: keyCombination, type: 'iframeKeydown' }, '*');
                }else{
                window.parent.postMessage({ key: e.key, type: 'iframeKeydown' }, '*');}
            }

            if(newIndex!==null && newIndex!==this.state.imageIndex){
                this.setState((prev)=>({
                    ...prev,
                    imageIndex : newIndex,
                    imgId: this.state.images[newIndex].imageId
                }))
            }
            if(!calledFromMessage)
                this.sendMessageToIframe('key_down', e);
            
        }catch(err){
            // console.log(err)
        }

    };


    /**
     * Get img container dimensions
     */
    updateNewImageDimensions=()=>{
        try {
            const containerRef = document.getElementById('imageActualContainer');
            if(!containerRef) return;
            const containerCoordinates= containerRef.getBoundingClientRect()
            const container = {
                width: containerCoordinates.width,
                height: containerCoordinates.height
            }
            const image = {
                width: containerRef.children[0].naturalWidth,
                height: containerRef.children[0].naturalHeight
            }
            let ratio_diff = parseFloat(container.height) / parseFloat(image.height) ;
            let newImage = {
                width: parseFloat(ratio_diff) * image.width,
                height: parseFloat(ratio_diff) * image.height
            }
            let padding = (container.width - newImage.width )/2;
            if(padding<0){
                ratio_diff = parseFloat(container.width) / parseFloat(image.width);

                newImage = {
                    width: ratio_diff * image.width,
                    height: ratio_diff * image.height
                }
                padding = (container.height - newImage.height )/2;
                
                // console.log({'ratio_diff': ratio_diff,'image':image,'container':container,'newImage': newImage,'padding': padding})
                this.setState(prev=>({
                    ...prev,
                    imageNewDimensions : {
                        isNewDimensionsUpdated : true,
                        isPadByWidth: false,
                        newImgWidth: newImage.width || 1,
                        newImgHeight: newImage.height || 1,
                        newContainerWidth: container.width || 1,
                        newContainerHeight: container.height || 1,
                        paddingHalf: padding,
                        notMoveImageOnHover : true
                    }
                }))

            }else{

                this.setState(prev=>({
                    ...prev,
                    imageNewDimensions: {
                        isNewDimensionsUpdated : true,
                        isPadByWidth: true,
                        newImgWidth: newImage.width || 1,
                        newImgHeight: newImage.height || 1,
                        newContainerWidth: container.width || 1,
                        newContainerHeight: container.height || 1,
                        paddingHalf: padding
                    }
                }))
                // console.log({'ratio_diff': ratio_diff,'image':image,'container':container,'newImage': newImage,'padding': padding})
            }
        } catch (error) {
            //console.log(error)
        }
    }

    calculateLeftAsPerAspect = (leftPercentageFromImageLeft)=>{
        try {
            // debugger
            if(!leftPercentageFromImageLeft) return 50;
            if(!this.state.imageNewDimensions.isNewDimensionsUpdated)
                this.updateNewImageDimensions();
            let newImageDimensions = this.state.imageNewDimensions;
            if(!newImageDimensions.isPadByWidth) return leftPercentageFromImageLeft;
            // convert to pixels
            const leftPixelFromImgLeft = (leftPercentageFromImageLeft * newImageDimensions.newImgWidth )/100;
            const newLeftFromContainer = leftPixelFromImgLeft + newImageDimensions.paddingHalf;
            const newPercentFromContainer =  ( newLeftFromContainer / newImageDimensions.newContainerWidth )*100;
            return newPercentFromContainer
        } catch (error) {
            // console.log(error);
        }
    }

    calculateTopAsPerAspect = (topPercentageFromImageTop)=>{
        try {
            if(!topPercentageFromImageTop) return 50;

            if(!this.state.imageNewDimensions.isNewDimensionsUpdated)
                this.updateNewImageDimensions();
            let newImageDimensions = this.state.imageNewDimensions;
            if(newImageDimensions.isPadByWidth) return topPercentageFromImageTop;
            // convert to pixels
            const topPixelFromImgTop = (topPercentageFromImageTop * newImageDimensions.newImgHeight )/100;
            const newTopFromContainer = topPixelFromImgTop + newImageDimensions.paddingHalf;
            const newPercentFromContainer =  ( newTopFromContainer / newImageDimensions.newContainerHeight )*100;
            return newPercentFromContainer;
        } catch (error) {
            //console.log(error);   
        }
    }

    handleHotSpotClick = async(e,hotspotObjKey,x = 0,y=0)=>{
        try {
            if(e)e.preventDefault();
            let modalListKeys = [...this.state.modalList.keys()];
            let index = modalListKeys?.findIndex(hotspotkey => hotspotkey == hotspotObjKey)
            // console.log(index,'hotspotclick')
            this.setState({
                ...this.state,
                goToSlide : index,
                activeHotspot : hotspotObjKey,
                selectedHotspotObjKey : hotspotObjKey,
                automatedImageSpin: false,
                hotspotActiveValue : { x , y},
            })
            this.updateStats({hotspotObjKey});
            let hotspot_name = this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).reasons[0];
            // captureEvents({event: 'hotspot_clicked', custom: {
            //     'hotspot_name': hotspot_name,
            //     'frame_number': [this.state.imageIndex]
            // }})
            const { skuName, skuId } = this.getSkuIdAndImageCategory();
            amplitudeEvents({
                event: 'click_hotspot',
                customAmplitudeEvents: {
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download, 
                    hotspot_name: hotspot_name,
                    skuName: skuName
                }
            })


        } catch (error) {
            // console.log(error)
        }
    }

    showHotspot=()=>{
        try {
            this.setState({
                ...this.state,
                showHotspotWhenImagesLoaded : true
            })
        } catch (error) {
            
        }
    }

    checkCategory(skuDetails){
        try {
            let contain3dImages = false
            let containOriginalImages = false
            skuDetails.forEach((images)=>{
                if(images['image_category'] === "360_original") containOriginalImages = true
                if(images['image_category'] === "3D_Model") contain3dImages = true
            })
            //Json :for 360 original, get both 3d_model and 360_original , it should render only one image category
            if(contain3dImages && containOriginalImages) return '3D_Model'
            else if(!contain3dImages && containOriginalImages) return '360_original'
            else return '3D_Model'
        } catch (error) {
            return '3D_Model'
        }
    }


    updateStats = ({hotspotObjKey, viewedFullScreenModal, oldIndex, newIndex, fetchImagesAPITime, hotspotMapAPITime, imageLoadingTime}) => {
        try {            
            //rotationStats
            if (oldIndex && newIndex) { //Means the Car is Rotated

                let currentIndex = this.state.imageIndex;

                this.setState(() => ({
                    userStats: {
                        ...this.state.userStats,
                        rotationStats: {
                            ...this.state.userStats.rotationStats,
                            visitedIndexes: {
                                ...this.state.userStats.rotationStats.visitedIndexes,
                                [currentIndex]: true
                            }
                        }
                    }
                }), () => {
                    let noOfFramesVisited = Object.keys(this.state.userStats.rotationStats.visitedIndexes).length;
                    let percentageFramesVisited = (noOfFramesVisited / (this.state.noOfImg - 1)) * 100;
                    this.setState(() => ({
                        userStats: {
                            ...this.state.userStats,
                            rotationStats: {
                                ...this.state.userStats.rotationStats,
                                percentageFramesVisited: percentageFramesVisited
                            }
                        }
                    }), () => {
                        let currentFramesCount = this.state.userStats.rotationStats.mouseFramesCount;

                        this.setState({
                            userStats: {
                                ...this.state.userStats,
                                rotationStats: {
                                    ...this.state.userStats.rotationStats,
                                    mouseFramesCount: currentFramesCount + 1,
                                },
                            }
                        })
                    })
                })
            }
            //hotspotStats
            if (hotspotObjKey) {
            
                let hotspot_name = this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).reasons[0];
                let currentCount = this.state.userStats.hotspotStats.clickCount[hotspot_name] || 0;

                this.setState({
                    userStats: {
                        ...this.state.userStats,
                        hotspotStats: {
                            ...this.state.userStats.hotspotStats,
                            clickCount: {
                                ...this.state.userStats.hotspotStats.clickCount,
                                [hotspot_name]: currentCount + 1
                            }
                        }
                    }
                })
            }

            if(viewedFullScreenModal){
                this.setState({
                    ...this.state, 
                    userStats : {
                        ...this.state.userStats,
                        hotspotStats : {
                            ...this.state.userStats.hotspotStats,
                            viewedFullScreenModal: true
                        }
                    }
                })
            }

            const top10Hotspots = Object.entries(this.state.userStats.hotspotStats.clickCount)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);
            // console.log(top10Hotspots, 'top10hotspots')

            //timeStats
            if (fetchImagesAPITime) {
                this.setState({
                    userStats: {
                        ...this.state.userStats,
                        timeStats: {
                            ...this.state.userStats.timeStats,
                            fetchImagesAPITime: fetchImagesAPITime,
                        }
                    }
                })
            }
            if (hotspotMapAPITime) {
                this.setState({
                    userStats: {
                        ...this.state.userStats,
                        timeStats: {
                            ...this.state.userStats.timeStats,
                            hotspotDataAPITime: hotspotMapAPITime,
                        }
                    }
                })
            }

            if(imageLoadingTime){
                this.setState({
                    userStats: {
                        ...this.state.userStats,
                        timeStats: {
                            ...this.state.userStats.timeStats,
                            firstImageLoadTime: (imageLoadingTime - this.state.timeStart)/1000,
                        }
                    }
                })
            }
            
        }
        catch (error) {
        }
    }


    /**
     * touch grab
     * @param {*} e 
     */
    handleTouchStarted = (e)=>{
        if(e)e.stopPropagation()
        if(e)e.preventDefault()
        this.setState({...this.state, showHelperText: false});
        e.target.addEventListener('touchmove', this.move);
    }

    /**
    * touch ungrab
    * @param {*} e 
    */
    touchEnd = (e)=>{
        e.target.removeEventListener('touchmove', this.move)
    }


    /**
     * Hotspot toggle
     * @param {*} e 
     */
    handleHotSpotViewToggle = (e)=>{
        try {
            this.setState({
                ...this.state,
                toggleHotspotView : !this.state.toggleHotspotView,
                isZoomActive: false,
                currentZoomLevel: 100,
                activeHotspot : null,
                ToggleInteriorHotspots: !this.state.ToggleInteriorHotspots,
                selectedHotspotObjKey:null
            });
            if(this.state?.toggleHotspotView == false)
                {
                    const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
                    const time = offsetDate?.toISOString()?.slice(0, -1);

                    const { skuName, skuId } = this.getSkuIdAndImageCategory();
                    amplitudeEvents({
                       event: 'hotspot_toggle_on' , 
                        customAmplitudeEvents: {
                            iframe_session_id: this.state?.sessionId,
                            sku_id: skuId || this.state?.skuId_for_download ,
                            enterprise_id: this.state?.enterpriseid_for_download,
                            time: time, 
                            skuName: skuName
                            }
                    })
                }
         
            // if(this.state.toggleHotspotView) captureEvents({event: 'hotspot_toggle_off'})
            //     else captureEvents({event: 'hotspot_toggle_on'})
        } 
        
        
        
        catch (error) {
            //console.log(error)
        }
    }
    
    


    /**
     * @returns left position in pixels
     */
    computeHotSpotTogglePositionLeft = ()=>{
        try {
            // if padding is not by width, return 10px
            const newImageDimensions = this.state.imageNewDimensions;
            if(!newImageDimensions || !newImageDimensions.isPadByWidth) return 10;

            // else add padding + 10 pixels
            return newImageDimensions.paddingHalf + 10;
        } catch (error) {
            // console.log(error);
        }
    }

    /**
     * @returns top position in pixels
     */
    computeHotSpotTogglePositionTop = ()=>{
        try {
            // if padding is by width, return 10px
            const newImageDimensions = this.state.imageNewDimensions;
            if(!newImageDimensions) return 10;

            let hotSpotToggleViewContainer = document.getElementById('hotspot-toggle-view-id');
            let containerHeight = 30;
            if( hotSpotToggleViewContainer){
                containerHeight = hotSpotToggleViewContainer.getBoundingClientRect().height;
            }
            // if padding by width
            if(newImageDimensions.isPadByWidth){
                return newImageDimensions.newImgHeight - containerHeight - 10 ;
            }
            // else add padding + newImageHeight - 10px (from bottom) - height os hotspotcontainer pixels
            return newImageDimensions.paddingHalf + newImageDimensions.newImgHeight - containerHeight - 10 ;
        } catch (error) {
            //console.log(error);
        }
    }

    // componentDidUpdate = ()=>{
    //     if(this.state.showHelperText){
    //         setTimeout(()=>{
    //             this.setState({
    //                 ...this.state,
    //                 showHelperText: false
    //             })
    //         },4000)
    //     }
    // }
    handleZoomIn = () => {
        this.setState((prevState) => ({
            ...prevState,
            isZoomActive: true,
            showHelperText: false,
            currentZoomLevel: parseInt(prevState.currentZoomLevel) + 25,
            toggleHotspotView :false
        }));
        if (this.zoomInBtn.current) {
            this.zoomInBtn.current.click();
        }

    };

    handleZoomOut = () => {
        this.setState((prevState) => ({
            currentZoomLevel: parseInt(prevState.currentZoomLevel) - 25
        }));
        if (this.zoomOutBtn.current) {
            this.zoomOutBtn.current.click();
        }
        if(this.state.currentZoomLevel === 125){
            this.setState((prev)=>({
                ...prev,
                isZoomActive: false,
                toggleHotspotView:false

            }))
        }
    };

    handleReset = () => {
        this.setState((prevState) => ({
            ...prevState,
            isZoomActive: false,
            currentZoomLevel: 100,
            toggleHotspotView:false
        }));
        this.resetBtn.current.click();
    };

    handleZoomSlider = (value) => {
        try {
            if(value === this.state.currentZoomLevel) return;
            if(value === 100){
                this.handleReset();
                return;
            }

            if(value < this.state.currentZoomLevel){
                this.handleZoomOut();
            }else{
                this.handleZoomIn();
            }

        } catch (error) {

        }
    }
    updateState = (value,key) => {

        // console.log("This is the key" , key , "and this is the value" , value);
        const {skuName , skuId} = this.getSkuIdAndImageCategory();
        if(key === 'INT' ){
            this.setState({
                ...this.state , isIntTabActive: true 

            })
          

        } 
        
        try {
            // if(value === 'EXT') captureEvents({event: 'exterior_360_switcher'})
            if(value === 'INT'){ 
                const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
                const time = offsetDate?.toISOString()?.slice(0, -1);
                amplitudeEvents({
                    event: 'click_interior',
                    customAmplitudeEvents: {
                        iframe_session_id: this.state?.sessionId,
                        sku_id: skuId || this.state?.skuId_for_download ,
                        enterprise_id: this.state?.enterpriseid_for_download,
                        skuName: skuName,
                        time: time
                        }
                })
                
            }
          
            if(key === "exteriorInterior"){
                this.setState({
                    ...this.state, showExtOrInt: value, isLoaded: false,
                    isZoomActive: false,
                    currentZoomLevel: 100,
                })
                //SEND event to parent
                if(value === 'INT' || value==='UNDERNEATH'){
                    //send a postmessage to parent
                    window.parent.postMessage(value, '*');
                }
                if(value === 'EXT'){
                    //send postimessage to iframe
                    this.sendMessageToIframe(value)
                }

                if(value === 'UNDERNEATH'){ 
                    const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
                    const time = offsetDate?.toISOString()?.slice(0, -1);
                    amplitudeEvents({
                        event: 'click_Underneath',
                        customAmplitudeEvents: {
                            iframe_session_id: this.state?.sessionId,
                            sku_id: skuId || this.state?.skuId_for_download ,
                            enterprise_id: this.state?.enterpriseid_for_download,
                            skuName: skuName,
                            time: time
                            }
                    })
                    
                }



            }else{
                this.setState({
                    ...this.state,
                    showhotspotBeforeAfter : value === "hotspot" ? false : true,
                    activeToggleBeforeAfter : value,
                })
            }

            this.resetBtn.current.click();
        } catch (error) {

        }
    }
    handleOnLoad = () => {
        try {
            this.setState({...this.state,isLoaded : true})
        } catch (error) {

        }
    }
    handleMouseOver(index) {
        try {
            this.setState({
                ...this.state,
                showValue : { [index] : true }
            })
        } catch (error) {

        }
    }
    hideLabel() {
        try {
            this.setState({
                ...this.state,
                showValue : {}
            })
        } catch (error) {

        }
    }
    handleAnimationEnd = (index) => {
        // console.log('Animation finished');
        try {
            if (this.elementRef.current) {
                this.elementRef.current.style.transform = 'scale(1)';
            }
        } catch (error) {

        }
        // Your code to run after animation finishes
    };

    increment = async () => {
        try {
            let modalListKeys = [...this.state.modalList.keys()];
            let index = this.state.images.findIndex(obj => obj.imageId == this.state.modalList.get(modalListKeys[this.state.goToSlide + 1 >= modalListKeys.length ? 0 : this.state.goToSlide + 1])?.image_id)
            if(index >= 0){
                await this.setState({
                    ...this.state,
                    hotSpotModal: { show: true },
                    goToSlide: this.state.goToSlide + 1 >= modalListKeys.length ? 0 : this.state.goToSlide + 1,
                    imageIndex: index,
                    selectedImageID: this.state.images[index].imageId,
                    imgId: this.state.images[index].imageId,
                    selectedHotspotObjKey : modalListKeys[this.state.goToSlide + 1 >= modalListKeys.length ? 0 : this.state.goToSlide + 1]

                })

                // }    
            }else{
                if (this.state.goToSlide + 1 === modalListKeys.length) {
                    this.setState({
                        ...this.state,
                        hotSpotModal: { show: true },
                        goToSlide: 0,
                    })
                }else{
                    this.setState({
                        ...this.state,
                        hotSpotModal: { show: true },
                        goToSlide: this.state.goToSlide + 1,
                    })
                }
            }

        } catch (error) {
            // console.log(error)
        }
    }


    decrement = async () => {
        try {
            let modalListKeys = [...this.state.modalList.keys()];
            let index = this.state.images.findIndex(obj => obj.imageId == this.state.modalList.get(modalListKeys[this.state.goToSlide - 1 <  0 ? modalListKeys.length - 1 : this.state.goToSlide - 1])?.image_id)
            if(index >= 0){
                await this.setState({
                    ...this.state,
                    hotSpotModal: { show: true },
                    goToSlide: this.state.goToSlide - 1 <  0 ? modalListKeys.length - 1 : this.state.goToSlide - 1,
                    imageIndex: index,
                    selectedImageID: this.state.images[index].imageId,
                    imgId: this.state.images[index].imageId,
                    selectedHotspotObjKey : modalListKeys[this.state.goToSlide - 1 <  0 ? modalListKeys.length - 1 : this.state.goToSlide - 1]

                })
            }else{
                if (this.state.goToSlide - 1 < 0) {
                    this.setState({
                        ...this.state,
                        hotSpotModal: { show: true },
                        goToSlide: modalListKeys.length,
                    })
                }else{
                    this.setState({
                        ...this.state,
                        hotSpotModal: { show: true },
                        goToSlide: this.state.goToSlide - 1,
                    })
                }
            }

        } catch (error) {
            // console.log(error)
        }
    }

    focusPoint=async(index)=>{
        try {
            if(index == this.state.goToSlide)return;
            let modalListKeys = [...this.state.modalList.keys()];
            let imageIndex = this.state.images.findIndex(obj => obj.imageId == this.state.modalList.get(modalListKeys[index])?.image_id)
            await this.setState((prev)=>({
                ...prev,
                hotSpotModal: { show: true },
                goToSlide: index,
                imageIndex: imageIndex,
                selectedImageID: this.state.images[imageIndex].imageId,
                imgId: this.state.images[imageIndex].imageId,
                selectedHotspotObjKey : modalListKeys[index]

            }))
        } catch (error) {

        }
    }
    

    Capitalize = (sentence) => {
        if (!sentence) return '';
        try {
            let words = sentence?.split(/[\s_]+/);
            let capitalizedWords = words?.map(word => {
                return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
            });
            return capitalizedWords.join(" ");
        } catch (error) {
        }
    }
    ConvertingPercentageToPixel=(valueInPercentage,coordinatesValue = "x",value)=>{
        try {
            if(coordinatesValue === "x"){
                let percentageValue = (valueInPercentage/100) *  this.state.imageNewDimensions.newImgWidth
                return percentageValue + value;
            }else{
                let percentageValue = (valueInPercentage/100) * this.state.imageNewDimensions.newImgHeight
                return percentageValue + value;
            }
        } catch (error) {

        }
    }
    isImageLoaded=()=>{
        try {
             if (this.state.imageIndex || this.state.isFunctionLoaded) return; //only 0 imageindex image loaded store
                                   const {skuId , skuName , enterpriseId} = this.getSkuIdAndImageCategory();
                        const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
                        const time = offsetDate?.toISOString()?.slice(0, -1);
                        // this.captureEvent('first_image_load');
                             amplitudeEvents({
                                 event: 'first_image_loaded',
                                 customAmplitudeEvents: {
                    iframe_session_id: this.state.sessionId,
                                     sku_id: skuId,
                                     enterprise_id: enterpriseId,
                                     skuName: skuName,
                                     time: time
                                 }
                                 
                             })

            this.setState({
                ...this.state,
                imageLoaded : true,
                isFunctionLoaded : true
            }
            , ()=>{
                if(!this.calledOnce && this.state.automatedImageSpin){
                    this.calledOnce=true;
                    this.handleImageSpin();
                }
            }
        )

        } catch (error) {

        }
    }


    updateIndexValue = () => {
        try {
            const {hotspot_val}= this.getSkuIdAndImageCategory();
            if (  !this.state.imageLoaded || (!this?.state?.automatedImageSpin && !this?.state?.spinMotion) ) return;
            const intervalSpan = this?.state?.spinMotion ? 75 : 150;
            this.interval = setInterval(() => {
              const continueCondition =
                this?.state?.imageIndex === 0 ||
                (this?.state?.clockwise
                  ? this?.state?.imageIndex + 1 < this.state.images.length
                  : this?.state?.imageIndex - 1 > 1);
              
              if (
                continueCondition &&
                this?.state?.automatedImageSpin
              ) {
                let newIndex;
                if (this?.state?.clockwise) {
                  newIndex =
                    (this?.state?.imageIndex + 1) % this?.state?.images.length;
                } else {
                  newIndex =
                    this?.state?.imageIndex === 0
                      ? this?.state?.images?.length - 1
                      : this?.state?.imageIndex - 1;
                }

                let newImageId = this?.state?.images?.[newIndex]?.imageId;
                this.setState((prevState) => ({
                  ...prevState,
                  imageIndex: newIndex,
                }));
                if (this?.state?.imgId !== newImageId) {
                  this.setState({ ...this.state, imgId: newImageId });
                }
              } else {
                //reached end of spin
                this.clearInterval(this.interval);
                this.setState((prevState) => ({
                  ...prevState,
                  toggleHotspotView: !hotspot_val? false : this.state.automatedImageSpin && true,
                  automatedImageSpin: false,
                }));
              }
            }, intervalSpan);
        } catch (error) {
            // console.log(error);
        }
    };

    handleImageSpin = () => {
        this.setState((prevState) => ({
            ...prevState,
            toggleHotspotView: false,
        }));
        try {
            this.updateIndexValue();

      } catch (error) {}
      }


    clearInterval = () => {
        try {
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        } catch (error) {

        }
    };

    handleMouseEnter = () => {
        try {
            this.updateIndexValue();
        } catch (error) {

        }
    };

    handleMouseLeave = () => {
        try {
            this.clearInterval();
        } catch (error) {

        }
    };

    changeGridListView=()=>{
        try {
            this.setState({...this.state,showhotspotBeforeAfter:!this.state.showhotspotBeforeAfter})
        } catch (error) {
            
        }
    }
    

    selectedName=(item)=>{
        const {image_name,output_image_hres_url, input_image_hres_url , tags={} , image_id} = item
        try {
            // console.log(name,url)
            // if(image_name === this?.state?.selectedUrl?.url)
            this.setState((prev)=>({...prev,isLoaded:false, selectedUrl:{ name:image_name, url:output_image_hres_url?output_image_hres_url : input_image_hres_url, tags : tags , image_id : image_id},showDropdown:false}))
        } catch (error) {
            // console.log(error)
        }
    }
    selectedUnderneathName=(item)=>{
        const {image_name,input_image_hres_url,tags={}} = item
        try {
            this.setState((prev)=>({...prev,isLoaded:false,
                
                selectedUnderneathUrl:{ name:image_name, url:input_image_hres_url, tags : tags},showDropdown:false}))
        } catch (error) {
            // console.log(error)
        }
    }
    updateDropdown=()=>{
        try {
            this.setState({...this.state,showDropdown: !this.state.showDropdown})
        } catch (error) {
            
        }
    }


   
    downloadVideo = async (enterprise_id_download, sku_id_download) => {
        const queryParams = new URLSearchParams(window.location.search);
        const sourceValue = queryParams.get('sku_name') ? 'WEBSITE' : 'IFRAME';
        let retryCount = 0;
        const maxRetries = 5; 
        const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
         const time = offsetDate.toISOString()?.slice(0, -1);
        const makeRequest = async () => {
          try {
            if (retryCount === 0) {
              this.setState({
                isDownloading: true,
                downloadProgress: 0,
              });
            }

            //Amplitude Events
            const { skuName, skuId } = this.getSkuIdAndImageCategory();
            amplitudeEvents({
                event: 'click_download',
                customAmplitudeEvents: {
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download, 
                    time: time,
                    skuName: skuName
                }
            })
            
            const res = await centralAPI.handleGetRequest(
              `${process.env.REACT_APP_BASEURL_API_SPYNE}/spin/download-video`,
              {
                enterprise_id: enterprise_id_download,
                sku_id: sku_id_download,
                source: sourceValue,
              }
            );
            const statusCode = parseInt(res.statusCode, 10);
            if (statusCode === 102) {
              toast.info('Download in Progress...', {
                autoClose: 3000,
                position: 'bottom-center'
              });
            } else if (statusCode === 500 || statusCode === 404) {
              toast.info('Video Download Failed...', {
                autoClose: 3000,
                position: 'bottom-center'
              });
            }
      
            const videoUrl = res.data.output_video_url;
            if (!videoUrl) {
              if (retryCount === 0) {
                this.progressInterval = setInterval(() => {
                  this.setState((prevState) => {
                    const newProgress = Math.min(prevState.downloadProgress + 5, 95);
                    if (newProgress >= 95) {
                      clearInterval(this.progressInterval);
                      this.progressInterval = null;
                    }
                    return { downloadProgress: newProgress };
                  });
                }, 1000);
              }
      
              if (retryCount < maxRetries) {
                retryCount++;
                setTimeout(() => {
                  makeRequest();
                }, 20000); 
              } else {
                toast.error('Video URL not available after multiple attempts.');
                if (this.progressInterval) {
                  clearInterval(this.progressInterval);
                  this.progressInterval = null;
                }
                this.setState({ isDownloading: false, downloadProgress: 0 });
              }
              return;
            }
      
            if (this.progressInterval) {
              clearInterval(this.progressInterval);
              this.progressInterval = null;
            }
            this.setState({ downloadProgress: 100 });
      
            const link = document.createElement('a');
            link.href = videoUrl;
            const fileExtension = videoUrl.split('.').pop().toLowerCase();
            link.download = `360_video_${sku_id_download}.${fileExtension}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      
            setTimeout(() => {
              this.setState({ isDownloading: false, downloadProgress: 0 });
            }, 1000);
          } catch (error) {
            console.error('Error downloading video:', error);
            if (this.progressInterval) {
              clearInterval(this.progressInterval);
              this.progressInterval = null;
            }
            this.setState({ downloadProgress: 0, isDownloading: false });
          }
        };
      
        await makeRequest();
      };
      
    handleOnLoad = () => {
        this.setState({ hotspot_val: true });
    };
    render() {
    

        return (
            (window?.innerWidth > 440 || this.state.showMinWidthWebView || this.state.spinMotion) ?
            <div>
                
                        <div className={`${this.state.showExtOrInt === "EXT" ? Styles.visible : Styles.hidden} ${Styles['view-360-container']}`}>
                            {
                                (this.state.loading  || this.state.showMinWidthWebView == null || this.state.spinMotion == null) ? (
                                    <Loader />
                                ) : (
                                    <div className={Styles[this.state.beforAfterView ? "view-360-slider-container" : ""]}>
                                        {this.state.beforAfterView ? <BeforeAfterHeading showhotspotBeforeAfter={this.state.showhotspotBeforeAfter} /> : null}
                                        <div className={Styles[this.state.beforAfterView ? "images-middle-container" : null]}>
                                            <div className={Styles[this.state.beforAfterView ? "list-grid-view" : null]}>
                                                {this.state.beforAfterView ? <BeforeContainer showhotspotBeforeAfter={this.state.showhotspotBeforeAfter} video360Urls={this.state.video360Urls} IsVdp={this.state.IsVdp} skuId={this.state.skuId}/> : null}
                                                {/* After col  */}
                                                <div className={Styles[this.state.beforAfterView ? this.state.showhotspotBeforeAfter ? "grid-view" : "max-list-view" : 'full-screen']}>
                                              
                                                    <div className={Styles[this.state.beforAfterView ? "after-image" : ""]}>
                                                        <div >
                                                            <div className={[Styles['front'], 'd-flex', 'justify-content-center', 'align-items-center'].join(' ')}>
                                                                {
                                                                    this.state.images.length && this.state.lazyLoadImages ? (
                                                                        <div className={[Styles[this.state.imageLoaded ? this.state.beforAfterView ? 'slickListContainerbeforeAfter' : 'slickListContainer' : 'slickListContainerDisable']]} >
                                                                            <div className={[Styles['viewContainer']]}>
                                                                                <div className={`${this.state.isZoomActive ? Styles['hidden'] : this.state.beforAfterView ? Styles['before-after-cont'] : ''}`} id="imageActualContainer" 
                                                                                    onMouseEnter={ ()=>{
                                                                                        if(this.state.automatedImageSpin) return;
                                                                                        this.handleMouseEnter(); this.updateNewImageDimensions()
                                                                                    }
                                                                                    }
                                                                                    
                                                                                    onMouseLeave={()=>{
                                                                                        if(this.state.automatedImageSpin) return;
                                                                                        else {
                                                                                            this.handleMouseLeave()};
                                                                                    }}
                                                                                    >

                                                                                        
                                                                                    
                                                                                    {this.state.imageLoaded || this.state.spinMotion ? <></> : <Loader />}

                                                                                    <img
                                                                                        src={this.state.images[this.state.imageIndex]?.src}
                                                                                        alt={this.state.images[this.state.imageIndex]?.imageName || ''}
                                                                                        id={this.state.images[this.state.imageIndex]?.imageId}
                                                                                        onMouseDown={this.handleMouseDown}
                                                                                        onMouseUp={this.handleMouseUp}
                                                                                        onTouchStart={(e) => this.handleTouchStarted(e)}
                                                                                        onTouchEnd={this.touchEnd}
                                                                                        draggable="false"
                                                                                        onLoad={this.isImageLoaded}
                                                                                        fetchpriority="high"
                                                                                    />
                                                                                    <PreFetchImages imagesLoadHandler={this.handleImagesLoaded} images={this.state.images} showHotspot={this.showHotspot} imageLoaded={this.state.imageLoaded} captureEvent={this.captureEvent} skuId = {this.state?.skuId_for_download} skuName={this.state?.skuName} enterpriseId = {this.state?.enterpriseid_for_download} />
                                                                                    {this.state.imageLoaded && (!this.state.spinMotion && this.state.spinMotion !== null)? <View360HelperText showHelperText={this.state.showHelperText} /> : null}
                                                                                    {(this.state.activeHotspot && this.state.isAnyFocusImage) ?
                                                                                        <View360Slider
                                                                                            modalList={this.state.modalList}
                                                                                            hideModal={() => this.setState({ ...this.state, hotSpotModal: { show: false }, activeHotspot: null, selectedHotspotObjKey: null })}
                                                                                            goToSlide={this.state.goToSlide}
                                                                                            increment={this.increment}
                                                                                            decrement={this.decrement}
                                                                                            name={this.state.images[this.state.imageIndex]?.imageName}
                                                                                            viewedFullScreenModal = {() => {
                                                                                                let hotspot_name = this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${this.state.selectedHotspotObjKey}`).reasons[0];
                                                                                                this.updateStats({viewedFullScreenModal: true})
                                                                                                // captureEvents({event: 'clicked_full_screen_modal', custom: {
                                                                                                //     'hotspot_name': hotspot_name,
                                                                                                //     'frame_number': [this.state.imageIndex]
                                                                                                // }})
                                                                                            }}
                                                                                        />
                                                                                        : null}
                                                                                </div>
                                                                               <div className={`${!this.state.isZoomActive ? Styles['hidden'] : Styles['zoomedImageContainer']}`}></div>
                                                                                {

                                                                                    (
                                                                                        this.props.hotSpotMapObj.get(`${this.state.imgId}`) && this.state.showHotspotWhenImagesLoaded && this.state.toggleHotspotView && this.state.imageLoaded && this.state.lazyLoadImages && !this.state.spinMotion && !this.state.showhotspotBeforeAfter) ? (
                                                                                        [...this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.keys()].map((hotspotObjKey, index) => {

                                                                                            if (!this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x || !this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y) return;
                                                                                            return (
                                                                                                <div
                                                                                                    key={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotId}
                                                                                                    className="d-inline-flex align-items-center"

                                                                                                >
                                                                                                    {(this.state.showValue?.[index] && (this.state.selectedHotspotObjKey !== hotspotObjKey || !this.state.isAnyFocusImage)) ? <div className={[Styles["DentIcon__container"]]}
                                                                                                        style={{
                                                                                                            left: `calc(${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%)`,
                                                                                                            top: `calc(${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}% )`,
                                                                                                            transform: `translate(-17%, -21%)`
                                                                                                        }}
                                                                                                    >
                                                                                                        <svg
                                                                                                        className={[Styles["DentIcon__wrap"]]} viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path
                                                                                                                d="M42.3541 11.0119C37.7055 6.36331 31.4007 3.75177 24.8266 3.75177C18.2526 3.75177 11.9477 6.36331 7.29917 11.0119C2.6506 15.6604 0.0390625 21.9653 0.0390625 28.5393C0.0390625 35.1134 2.6506 41.4182 7.29917 46.0668C11.9477 50.7154 18.2526 53.3269 24.8266 53.3269C31.4007 53.3269 37.7055 50.7154 42.3541 46.0668C47.0027 41.4182 49.6142 35.1134 49.6142 28.5393C49.6142 21.9653 47.0027 15.6604 42.3541 11.0119L54.0391 -0.673096"
                                                                                                                fill="transparent" stroke="#fff" stroke-width="1.5"
                                                                                                                onAnimationEnd={() => this.handleAnimationEnd(index)}

                                                                                                            ></path>
                                                                                                        </svg>

                                                                                                        <label ref={this.elementRef} className={[Styles["DentIcon__label"], Styles["DentIcon__open"]].join(' ')}>{this.Capitalize(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`)?.reasons?.[0] ?? 'info')}</label>
                                                                                                    </div> : null}

                                                                                                    {(this.state.selectedHotspotObjKey === hotspotObjKey && this.state.isAnyFocusImage) ? <>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className={[Styles['active-line-hotspot']]} width="100%" height="100%">
                                                                                                            <line fill="none" stroke="#fff" x1={`calc(${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}% + 1.2%)`} x2={this?.state?.imageNewDimensions?.newImgWidth ? this.state.imageNewDimensions.newImgWidth - 100 : window?.innerWidth > 1200 ? window?.innerWidth - 200 : window?.innerWidth - 100} y1={`calc(${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}% - 0.8%)`} y2="83" style={{ zIndex: 10 }} filter="url(#filter0_d_21310_177698)"></line>
                                                                                                        </svg>
                                                                                                        <svg style={{
                                                                                                            left: `calc(${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%)`,
                                                                                                            top: `calc(${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}% )`,
                                                                                                            transform: `translate(-17.7%, -18.5%)`
                                                                                                        }} className={[Styles['active-hotspot']]}>
                                                                                                            <circle cx="50%" cy="50%" r="16.5" fill-opacity="0" stroke="#fff" style={{ zIndex: 10 }} className={[Styles["circle"]]} stroke-width="1"></circle>
                                                                                                        </svg>
                                                                                                    </> : null}
                                                                                                    <img
                                                                                                        src={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotspot_icon}
                                                                                                        className={[Styles['slickListContainerItemcentered']]}
                                                                                                        onClick={(e) => this.handleHotSpotClick(e, hotspotObjKey)}
                                                                                                        onMouseEnter={() => {
                                                                                                            this.handleMouseOver(index)
                                                                                                            // captureEvents({event: 'hover_on_hotspot', custom: {
                                                                                                            //     'hotspot_name': this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).reasons[0]
                                                                                                            // }})
                                                                                                        }}
                                                                                                        onMouseLeave={() => this.hideLabel()}
                                                                                                        id={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotId}
                                                                                                        alt={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotTitle}
                                                                                                        style={{
                                                                                                            left: `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`,
                                                                                                            top: `${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`,
                                                                                                        }}
                                                                                                        draggable="false"
                                                                                                    />
                                                                                                    <span
                                                                                                        className={[Styles[this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).type == "hotspot" ? 'slickListContainerItemcentered-span-hotspot' : 'slickListContainerItemcentered-span']]}
                                                                                                        style={{
                                                                                                            left: `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`,
                                                                                                            top: `${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`,
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        })

                                                                                    ) : (null)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    ) : (null)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(!this.state.loading || this.state.isLoaded || this.state.imageLoaded) ?
                                                        <div className={ Styles[this.state.showMinWidthWebView ? 'interior-exterior-container-sml-screen' : 'interior-exterior-container']}>
                                                        {!this.state.spinMotion && (<div className={[Styles['interior-exterior-container-panel']]}> 
                                                           
                                                                {((this.state.isAnyHotSpotPresent && !this.state.spinMotion && !this.state.showhotspotBeforeAfter)) ? <HotspotToggleHandler handleHotSpotViewToggle={this.handleHotSpotViewToggle} toggleHotspotView={this.state.toggleHotspotView}     ToggleInteriorHotspots={this.state.ToggleInteriorHotspots}     toggleInteriorHotspots={this.toggleInteriorHotspots}  /> : null}
                                                                {(this.state.isAnyHotSpotPresent && this.state.imageNewDimensions.isNewDimensionsUpdated && this.state?.interior360Url?.length > 0) ? <div className={[Styles["line"]]}></div> : null}
                                                                {(this.state?.interior360Url?.length > 0 && this.state.showHotspotWhenImagesLoaded)? <ToggleButton updateState={this.updateState} value={this.state} /> : null}
                                                                {this.state.showDownloadBtn && (
                                                                <div className={` ${Styles['Download-container-panel']}`}>
   
        <div>
            <button
                className="w-max h-10 p-2 flex flex-col justify-center items-center text-white rounded-md transition-colors overflow-hidden relative"
                onClick={() => this.downloadVideo(this.state?.enterpriseid_for_download, this.state?.skuId_for_download)}
                disabled={this.state.isDownloading}
            >
                <div className="flex items-center gap-1 z-10 relative">
                    <img src="https://spyne-static.s3.amazonaws.com/spin/DownloadIcon.svg" alt="Download Icon" />
                    <span 
                        className="text-[14px] font-medium tracking-tight" 
                        style={{ color: "#FFFFFFCC", letterSpacing: "-0.01122em" }}
                    >
                        {this.state.isDownloading ? 'Downloading...' : 'Download Spin'}
                    </span>
                </div>
                {this.state.isDownloading && (
                    <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-200">
                        <div
                            className="h-full bg-blue-600 transition-all duration-300 ease-in-out"
                            style={{ width: `${this.state.downloadProgress}%` }}
                        ></div>
                    </div>
                )}
            </button>
        </div>
</div>
    )}

                                                            </div>)}
                                                           

                                                          
                                                        </div> 
                                                        
                                                        
                                                        
                                                        : null}
                                                        
                                                </div>
                                            </div>
                                        </div>

                                    </div> 
                                )
                            }
                            {this.state.errStatus.show ?<FallbackUrl errStatus= {this.state.errStatus.code} isRequestFor3DModel ={this.state.isRequestFor3DModel} /> : null}
                        </div>
                        {/* //interior */}
                        { this.state.showExtOrInt==="INT" &&    
                        <div className={[Styles["interior-container"], 'container-fluid'].join(' ')}>
                            {/* {!this.state.isLoaded ? <Loader /> : null } */}
                            <div className={[Styles["interior-a-frame"]]}>
                                <div className={Styles['interior-iframe']}>
                                    {this?.state?.interior360Url.length > 1 ? <InteriorFrameDropdown state={this.state} selectedName={this.selectedName} updateInteriorDropdown={this.updateDropdown} /> : null}
                                </div>
                                {/* {console.log('interiorData:', this.state.interiorData)} */}
                                {/* {console.log('Hotspots being passed:', this.state.interiorData)} */}
                                <InteriorIframe handleOnLoad={this.handleOnLoad} state={this.state} isMobileView={false} hotspots={this.state.interiorData} ToggleInteriorHotspots = {this.state.ToggleInteriorHotspots} hotspotDataInterior = {this.state.hotspotDataInterior} hotspotImageId = {this.state.hotspotImageId} /> 
</div>
                            {(!this.state.loading || this.state.isLoaded || this.state.imageLoaded) ?
                                <div className={[Styles[this.state.showMinWidthWebView ? 'interior-exterior-container-sml-screen' : 'interior-exterior-container']]}>
                                    <div className={[Styles['interior-exterior-container-panel']]}>
                                        {((this.state.isAnyHotSpotPresent && !this.state.spinMotion && !this.state.showhotspotBeforeAfter)) ? <HotspotToggleHandler handleHotSpotViewToggle={this.handleHotSpotViewToggle} toggleHotspotView={this.state.toggleHotspotView} toggleInteriorHotspots={this.toggleInteriorHotspots} 
    ToggleInteriorHotspots={this.state.ToggleInteriorHotspots}  /> : null}
                                        {(this.state.isAnyHotSpotPresent && this.state.imageNewDimensions.isNewDimensionsUpdated && this.state?.interior360Url?.length > 0) ? <div className={[Styles["line"]]}></div> : null}
                                        {this.state?.interior360Url?.length > 0 ? <ToggleButton updateState={this.updateState} value={this.state} /> : null}
                                        
                                    </div>
                                    
                                </div> : null}

                        </div>
                    }

                    {/* underneathData */}
                     {(this.state.showExtOrInt !== "INT" && this.state.showExtOrInt!=="EXT") &&      
                     
                        <div className={[Styles["interior-container"], 'container-fluid'].join(' ')}>
                        
                        <div className={[Styles["interior-a-frame"]]}>
                            <div className={Styles['interior-iframe']}>
                                
                                {this?.state?.underneathData?.length > 1 ? <UnderneathFrameDropdown state={this.state} selectedName={this.selectedUnderneathName} updateDropdown={this.updateDropdown} /> : null}
                            </div>
                            <UnderneathIframe state={this.state} handleOnLoad={this.handleOnLoad} isMobileView={false} />
                        </div>
                        {(!this.state.loading || this.state.isLoaded || this.state.imageLoaded) ?
                            <div className={[Styles[this.state.showMinWidthWebView ? 'interior-exterior-container-sml-screen' : 'interior-exterior-container']]}>
                                <div className={[Styles['interior-exterior-container-panel']]}>
                                    {((this.state.isAnyHotSpotPresent && !this.state.spinMotion && !this.state.showhotspotBeforeAfter)) ? <HotspotToggleHandler handleHotSpotViewToggle={this.handleHotSpotViewToggle} toggleHotspotView={this.state.toggleHotspotView} /> : null}
                                    {(this.state.isAnyHotSpotPresent && this.state.imageNewDimensions.isNewDimensionsUpdated && this.state?.underneathData?.length > 0) ? <div className={[Styles["line"]]}></div> : null}
                                    {this.state?.underneathData?.length > 0 ? <ToggleButton updateState={this.updateState} value={this.state} /> : null}
                                    
                                </div>
                            </div> : null}
                       </div>
                     }
                    <div>
                        {(this.state.beforAfterView && window?.innerWidth > 440) ? <ToggleButton updateState={this.updateState} value={this.state} beforeAfterView={true} /> : null}
                    </div>
                </div>
                :
                <div className={[Styles['view-360-mobile']]} style={{ height: `${window?.innerHeight ? `${window?.innerHeight}px` : '100%'}` }}>
                    {this.state.showExtOrInt === "EXT" ?
                        <div className={Styles['view-360-container-mobile']}>
                            {
                                this.state.loading ? (
                                    <Loader /> 
                                ) : (
                                    <>
                                        {(!this.state.loading || this.state.isLoaded) && (!this.state.activeHotspot && this.state.isAnyFocusImage) ?
                                            <div className={[Styles['interior-exterior-container-mobile-view']]}>
                                                {(this.state.isAnyHotSpotPresent) ?
                                                    <MobileToggleHandler handleHotSpotViewToggle={this.handleHotSpotViewToggle} toggleHotspotView={this.state.toggleHotspotView}  /> : null}
                                            </div>
                                            : null}
                                        <div style={((this.state.activeHotspot && this.state.isAnyFocusImage) || (!this.state.toggleHotspotView && this.state.beforAfterView) || (this.state.beforAfterView && !this.state.isAnyHotSpotPresent)) ? { display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-between' } : { display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }} >
                                            {(this.state.beforAfterView && (!this.state.toggleHotspotView || !this.state.isAnyHotSpotPresent)) ?
                                                <MobileViewHeadingContainer beforAfterView={this.state.beforAfterView} video360Urls={this.state.video360Urls}/>
                                                : null}
                                            {(this.state.activeHotspot && this.state.isAnyFocusImage) ?
                                                <MobileViewSlider modalList={this.state.modalList}
                                                    hideModal={() => this.setState({ ...this.state, activeHotspot: null, selectedHotspotObjKey: null })}
                                                    goToSlide={this.state.goToSlide}
                                                    updateFocusPoint={this.focusPoint}
                                                    increment={this.increment}
                                                    decrement={this.decrement}
                                                    name={this.state.images[this.state.imageIndex]?.imageName} /> 
                                            : null}
                                            <div className={[Styles['front'], 'd-flex', 'justify-content-center', 'align-items-center'].join(' ')}>
                                                {this.state.beforAfterView && (!this.state.toggleHotspotView || !this.state.isAnyHotSpotPresent) ?<MobileViewAfterContainer/> : null}
                                                {
                                                    this.state.images.length && this.state.lazyLoadImages ? (
                                                        <div className={[Styles[this.state.imageLoaded ? 'slickListContainerMobileView' : 'slickListContainerMobileDisable']]} >
                                                            <div className={[Styles['viewContainer']]}>
                                                                <div className={`${this.state.isZoomActive ? Styles['hidden'] : ''}`} id="imageActualContainer">
                                                                    <img
                                                                        src={this.state.images[this.state.imageIndex]?.src}
                                                                        alt={this.state.images[this.state.imageIndex]?.imageName || ''}
                                                                        id={this.state.images[this.state.imageIndex].imageId}
                                                                        onMouseDown={this.handleMouseDown}
                                                                        onMouseUp={this.handleMouseUp}
                                                                        onTouchStart={(e) => this.handleTouchStarted(e)}
                                                                        onLoad={() => this.isImageLoaded()}
                                                                        onTouchEnd={this.handleTouchEnd}
                                                                        draggable="false"
                                                                    />
                                                                    <PreFetchImages imagesLoadHandler={this.handleImagesLoaded} images={this.state.images} showHotspot={this.showHotspot} imageLoaded={this.state.imageLoaded} captureEvent={this.captureEvent} skuId = {this.state?.skuId_for_download} skuName={this.state?.skuName}  enterpriseId = {this.state?.enterpriseid_for_download}/>

                                                                </div>

                                                                {
                                                                    (this.props.hotSpotMapObj.get(`${this.state.imgId}`) && this.state.toggleHotspotView && this.state.imageLoaded) ? (
                                                                        [...this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.keys()].map((hotspotObjKey, index) => {
                                                                            if (!this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x || !this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y) return;
                                                                            return (
                                                                                <div
                                                                                    key={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotId}
                                                                                    className="d-inline-flex align-items-center"

                                                                                >

                                                                                    {(this.state.selectedHotspotObjKey === hotspotObjKey && this.state.isAnyFocusImage) ? <>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className={[Styles['active-line-hotspot']]} width="100%" height="100%" role='presentation' aria-hidden="true" style={{ position: 'absolute', height: '700px', top: `-${(window?.innerHeight || 900) / 2.35}px` }}>
                                                                                            <defs>
                                                                                                <filter id="drop-shadow" x="-20%" y="-20%" width="140%" height="140%">
                                                                                                    <feDropShadow dx="0" dy="0" stdDeviation="6" flood-color="rgba(0, 0, 0, 0.32)" />
                                                                                                </filter>
                                                                                            </defs>
                                                                                            <line fill="none" stroke="#fff" x1="187.5" x2={this.ConvertingPercentageToPixel(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x, "x", 10)} y1="0" y2={this.ConvertingPercentageToPixel(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y, "y", (window?.innerHeight || 900) / 2.36)} style={{ filter: 'url(#drop-shadow)' }} stroke-width="1.3"></line>
                                                                                        </svg>
                                                                                        <svg style={{
                                                                                            left: `calc(${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%)`,
                                                                                            top: `calc(${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}% )`,
                                                                                            transform: `translate(-17.7%, -18.5%)`

                                                                                        }} className={[Styles['active-hotspot']]}>
                                                                                            <circle cx="50%" cy="50%" r="16.5" fill-opacity="0" stroke="#fff" style={{ zIndex: 10 }} className={[Styles["circle"]]} stroke-width="1.3"></circle>
                                                                                        </svg>
                                                                                    </> : null}
                                                                                    <img
                                                                                        src={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`)?.hotspot_icon}
                                                                                        className={[Styles['slickListContainerItemcentered']]}
                                                                                        onClick={(e) => this.handleHotSpotClick(e, hotspotObjKey, `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`, `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`)}
                                                                                        onMouseEnter={() => this.handleMouseOver(index)}
                                                                                        onMouseLeave={() => this.hideLabel()}
                                                                                        id={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotId}
                                                                                        alt={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotTitle}
                                                                                        style={{
                                                                                            left: `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`,
                                                                                            top: `${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`,
                                                                                        }}
                                                                                        draggable="false"
                                                                                    />
                                                                                    <span
                                                                                        className={[Styles[this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotTitle == "hotspot" ? 'slickListContainerItemcentered-span-hotspot' : 'slickListContainerItemcentered-span']]}
                                                                                        style={{
                                                                                            left: `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`,
                                                                                            top: `${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        })

                                                                    ) : (null)
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : (null)
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            {(!this.state.spinMotion && this.state.spinMotion !== null) ? <View360HelperText showHelperText={this.state.showHelperText} /> :null}
                            {this.state.errStatus.show ? <FallbackUrl errStatus={this.state.errStatus.code} isRequestFor3DModel={this.state.isRequestFor3DModel} /> : null}
                        </div>
                        : this.state.showExtOrInt === "INT"?
                        <div className={[Styles["interior-container"], 'container-fluid'].join(' ')}>
                            {!this.state.isLoaded &&  this.state.isIntTabActive?  <Loader /> : null}
                            <div className={[Styles["interior-a-frame"]]}>
                                <div className={Styles['interior-iframe']}>
                                    {this?.state?.interior360Url.length > 1 ? <InteriorFrameDropdown state={this.state} selectedName={this.selectedName} updateInteriorDropdown={this.updateDropdown} ToggleInteriorHotspots = {this.stateToggleInteriorHotspots} /> : null}
                                </div>
                                <InteriorIframe state={this.state} handleOnLoad={this.handleOnLoad} isMobileView={true} hotspotDataInterior = {this.state.hotspotDataInterior} hotspotImageId = {this.state.hotspotImageId}/>
                            </div>
                        </div>: 
                        <div className={[Styles["interior-container"], 'container-fluid'].join(' ')}>
                      
                        <div className={[Styles["interior-a-frame"]]}>
                            <div className={Styles['interior-iframe']}>
                                {this?.state?.underneathData.length > 1 ? <UnderneathFrameDropdown state={this.state} selectedName={this.selectedUnderneathName} updateDropdown={this.updateDropdown} /> : null}

                            </div>

                            <UnderneathIframe state={this.state} handleOnLoad={this.handleOnLoad} isMobileView={true} />

                        </div>
                    </div>
                    }
                    {(this.state?.interior360Url?.length > 0 && !(this.state.activeHotspot && this.state.isAnyFocusImage) && this.state.showInteriorFrame)?<ToggleButton updateState={this.updateState} value={this.state} mobileView={true} />: null}
                </div>
        )
    }
}


const mapStateToProps = (state, ownProps)=>{
    //console.log(state.view360Reducer)
    return{
        hotSpotMapObj : state.view360Reducer.hotSpotMapObj,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        updateWholeHotSpotMapObj: (hotSpotMapObj) => dispatch(updateWholeHotSpotMapObj(hotSpotMapObj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(View360);