/**
 * @author: ritesh.dabral
 * 
 * This page imports all constants, and creates required function
 * 
 */

 import { 
    UPDATE_WHOLE_HOTSPOT_MAP_OBJECT
   } from './View360ActionType'
  

   export const updateWholeHotSpotMapObj = (newHotSpotMapObj)=>{
     return {
       type: UPDATE_WHOLE_HOTSPOT_MAP_OBJECT,
       payload: newHotSpotMapObj,
       info: 'View360 Component - updateWholeHotSpotMapObj for entire view360 ActionController'
     }
   }
 
  // exports
  const view360ActionControllers = {
    updateWholeHotSpotMapObj
  }
  
  export default view360ActionControllers;