import React from 'react'
import Styles from '../../../Css/View360/View360.module.css'
import View360CommonStyle from '../../../Css/View360/View360Common.module.css'
import Switch from '@mui/material/Switch';
import { checkIsV2 } from '../../../utils/getAppVersion';

const MobileToggleHandler = ({ handleHotSpotViewToggle, toggleHotspotView }) => {
    const isV2 = checkIsV2()
    return (
        <div className={isV2 ? [View360CommonStyle['hotspot-info-tag-left-mobile-view']] : [Styles['hotspot-info-tag-left-mobile-view']]}>
            Hotspots
            <Switch
                onChange={handleHotSpotViewToggle}
                checked={toggleHotspotView}

                sx={{
                    '& .MuiSwitch-thumb': {
                        backgroundColor: toggleHotspotView ? '#4600F2' : undefined
                    }
                }}
                className={[Styles["customSwitchTrack"]]}
            />
        </div>
    )
}

export default MobileToggleHandler
