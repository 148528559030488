import React,{ useEffect, useState } from 'react'
import Styles from '../../Css/View360/View360.module.css'
import { IFRAME_MODULE_CONFIG } from '../common/config'
import { checkIsV2 } from '../../utils/getAppVersion';
export default function View360HelperText(
    showHelperText
) {
    const [showHotspot,setHotspot] = useState(false)
    const isV2 = checkIsV2();

    useEffect(()=>{
        const queryParams = new URLSearchParams(window.location.search);
        let hotspot_val = queryParams.get('hotspot')
        setHotspot(!!hotspot_val)
    },[])

    return (
        <>
        <div
            className={`${Styles[(window?.innerWidth >400  || showHotspot)  ?'helperTextContainer':'helperTextContainerMobile']} ${!showHelperText.showHelperText ? Styles['hideHelperTextContainer'] : ''} ${isV2 && "!top-[4%]"}`}
        >
            <img
                src="https://d20uiuzezo3er4.cloudfront.net/console/icons/raUFXQXzkM.gif"
                alt="handIcon"
                className={[Styles['helperTextHandIcon']]}
                
            />
            <div className={[Styles['helpTextContainerText']]}>Click and Drag to view 360 spin</div>
            <div className={[Styles['helpTextContainerTextMobile']]}>Swipe to view 360 spin</div>
        </div>
        </>
    )
}
