import React,{ PureComponent } from 'react'
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { connect } from 'react-redux'
import Styles from '../../Css/View360/View360.module.css'

class MobileViewSlider extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            modalList: new Map(),
            modalListKeys: [],
            goToSlide: 0,
            setTitle: '',
            imageCount : 0
        };
        
        // this.handleSlideChange = this.handleSlideChange.bind(this);
        this.UpdateHotspot = this.UpdateHotspot.bind(this)
        this.sliderRef = React.createRef()
    }

    // handleSlideChange(index) {
        // this.setState({ currentSlideIndex: index });
        // console.log({index})
       
      // }
      UpdateHotspot(prev,now){
        // console.log(prev,now)
        this.props.updateFocusPoint(now)
      }
    async componentDidUpdate() {
        this.sliderRef.slickGoTo(this.props.goToSlide);
        // this.props.updateFocusPoint(this.props.goToSlide)
        // console.log(this.sliderRef.slickGoTo)
        this.setState({
          goToSlide : this.props.goToSlide
        })
    }

    async componentDidMount() {
        let listOfModal = [...this.props.modalList.keys()]
        let count = 0 
        listOfModal.forEach((hotSpotKey)=>{
          if(this.props.modalList.get(hotSpotKey)?.closeUpURL)count++
        })
        let title = listOfModal[this.props.goToSlide]
        let getTitle = this.props.modalList.get(title).title;
        await this.setState({ ...this.state, modalList: this.props.modalList, modalListKeys: [...this.props.modalList.keys()], goToSlide: this.props.goToSlide, setTitle: getTitle ,imageCount : count})

        if (this.sliderRef) {
            this.sliderRef.slickGoTo(this.props.goToSlide);
        }
    }


    /**
     * Show modal on button click
     * @param {*} e 
     * @returns 
     */
    hotSpotModelViewBtnClick = (url) => {
        try {
            if (!url) return;
            window?.open(url);
        } catch (error) {
            //console.log(error)
        }
    }


    Capitalize = (sentence) => {
      if(!sentence) return '';
      try {
          let words = sentence.split(" ");
  
          // Capitalize the first character of each word
          let capitalizedWords = words.map(word => {
              // Capitalize the first character and concatenate with the rest of the word in lowercase
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          });
          
          // Join the words back into a single string
          return capitalizedWords.join(" ");
      } catch (error) {
          
      }
  }
    render() {
        let settings = {
            dots: false,
            infinite: true,
            speed: 400,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            // afterChange: this.handleSlideChange
            beforeChange : this.UpdateHotspot
          };
        return (
          <CrouselContainer>
            <TextAndCloseButton>
              <p className={[Styles['mobile-view-text']]}>{this?.state?.modalListKeys?.length || 0} Hotspots, {this.state.imageCount} Focus Shots available</p>
              <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>this.props.hideModal()}>
                <g filter="url(#filter0_d_21587_168331)">
                  <rect x="6.83594" y="7" width="32" height="32" rx="16" fill="white" shape-rendering="crispEdges" />
                  <path d="M22.8352 24.0213L19.258 27.5985C19.1241 27.7323 18.9538 27.7992 18.7469 27.7992C18.5401 27.7992 18.3698 27.7323 18.2359 27.5985C18.1021 27.4646 18.0352 27.2943 18.0352 27.0874C18.0352 26.8806 18.1021 26.7102 18.2359 26.5764L21.8131 22.9992L18.2359 19.422C18.1021 19.2882 18.0352 19.1178 18.0352 18.911C18.0352 18.7042 18.1021 18.5338 18.2359 18.4C18.3698 18.2661 18.5401 18.1992 18.7469 18.1992C18.9538 18.1992 19.1241 18.2661 19.258 18.4L22.8352 21.9772L26.4123 18.4C26.5462 18.2661 26.7165 18.1992 26.9234 18.1992C27.1302 18.1992 27.3006 18.2661 27.4344 18.4C27.5682 18.5338 27.6352 18.7042 27.6352 18.911C27.6352 19.1178 27.5682 19.2882 27.4344 19.422L23.8572 22.9992L27.4344 26.5764C27.5682 26.7102 27.6352 26.8806 27.6352 27.0874C27.6352 27.2943 27.5682 27.4646 27.4344 27.5985C27.3006 27.7323 27.1302 27.7992 26.9234 27.7992C26.7165 27.7992 26.5462 27.7323 26.4123 27.5985L22.8352 24.0213Z" fill="black" fill-opacity="0.6" />
                </g>
                <defs>
                  <filter id="filter0_d_21587_168331" x="0.435937" y="0.6" width="44.8" height="44.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3.2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21587_168331" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21587_168331" result="shape" />
                  </filter>
                </defs>
              </svg>
            </TextAndCloseButton>
            <Container>
                <Carousel {...settings} 
                        ref={slider => (this.sliderRef = slider)}>
                    {
                        this.state.modalListKeys.length ? this.state.modalListKeys.map((hotSpotKey,index) => {
                            return (
                                <Wrap>
                                    <a>
                                        <div  className={[Styles[index === this.props.goToSlide ? 'scroll-container-active' :'scroll-container']]}>
                                            <div className={[Styles['info-container']]}>
                                                <p>{this.Capitalize(this?.state?.modalList?.get(hotSpotKey)?.desc) || ''} <span>{this?.props?.goToSlide + 1} / {this?.state?.modalListKeys?.length || 0} </span></p>
                                            </div>
                                            <img src={this?.state?.modalList?.get(hotSpotKey)?.closeUpURL || ''} alt="" height={`${((window?.innerWidth - 79)/16)*9}px!important`}/>
                                        </div>
                                    </a>
                                </Wrap>
                            )
                        }) : null
                    }
                </Carousel>
            </Container>
          </CrouselContainer>
        );
    }
}
const TextAndCloseButton = styled.div`
  display : flex;
  align-items : center;
  justify-content : space-between;
  margin-top:0px;
  padding-right : 8px;
`


const CrouselContainer = styled.div`
  width : 100%;
  margin : 0px;
  padding : 0px
  z-index : 100;
  background: '#EAEAEA';
`


const Container = styled.main`
  position: relative;
  overflow-x: hidden;
  display: block;
  padding: 0 calc(3.5vw + 15px);
  border-radius : 8px;
  margin-top:0px;
  z-index : 50;
  height: 100%;
`;


const Carousel = styled(Slider)`
  margin-top: 20px;
  background : #EAEAEA;

  & > button {
    opacity: 0;
    height: 100%;
    width: 5vw;
    z-index: 1;

    &:hover {
      opacity: 1;
      transition: opacity 0.2s ease 0s;
    }
  }

  ul li button {
    // &:before {
    //   font-size: 10px;
    //   color: rgb(150, 158, 171);
    // }
  }

  li.slick-active button:before {
    color: white;
  }

  .slick-list {
    overflow: initial;
  }

  .slick-prev {
    left: -75px;
  }

  .slick-next {
    right: -75px;
  }
`;

const Wrap = styled.div`
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  a {
   
    cursor: pointer;
    display: block;
    position: relative;
    padding: 4px;

    img {
      width: 100%;
      max-height: 180px;
      border-bottom-left-radius:8px;
      border-bottom-right-radius:8px;
      object-fit : contain
    }

    &:hover {
      transition-duration: 300ms;
    }
  }
`;


const mapStateToProps = (state, ownProps) => {
    return {
        hotSpotMapObj: state.view360Reducer.hotSpotMapObj,
    }
}

export default connect(mapStateToProps, null)(MobileViewSlider);
