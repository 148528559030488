import React, { useEffect } from 'react';
import Styles from '../../Css/View360/View360.module.css';
import View360CommonStyle from '../../Css/View360/View360Common.module.css';
import { checkIsV2 } from '../../utils/getAppVersion';

const InteriorFrameDropdown = ({ state, updateInteriorDropdown, selectedName, ToggleInteriorHotspots }) => {
  const isV2 = checkIsV2()
  
  const toggleHotspotVisibility = () => {
    if (ToggleInteriorHotspots) {
      document.querySelectorAll('.pnlm-hotspot-base').forEach((e) => {
        e.style.visibility = 'hidden';
      });
    } else {
      document.querySelectorAll('.pnlm-hotspot-base').forEach((e) => {
        e.style.visibility = 'visible';
      });
    }
  };

  useEffect(() => {
    toggleHotspotVisibility();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          toggleHotspotVisibility();
        }
      });
    });

    const hotspotContainer = document.querySelector('.pnlm-container');
    if (hotspotContainer) {
      observer.observe(hotspotContainer, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [ToggleInteriorHotspots, state?.selectedUrl]);

  return (
    <div className={isV2 ? View360CommonStyle['dropdown-container'] : Styles['dropdown-container']}>
      <div className={Styles['header-container']} onClick={() => updateInteriorDropdown()}>
        <div>{state?.selectedUrl?.tags?.overlay_name || state?.selectedUrl?.name}</div>
        <img 
          alt="Caret icon" 
          srcSet="https://spyne-static.s3.amazonaws.com/console/filter/chevron_down_inactive.svg 1x, https://spyne-static.s3.amazonaws.com/console/filter/chevron_down_inactive.svg 2x" 
          src="https://spyne-static.s3.amazonaws.com/console/filter/chevron_down_inactive.svg" 
          width="24" 
          height="24" 
          decoding="async" 
          className={Styles[state.showDropdown ? 'dropdown-icon-noactive':'dropdown-icon-active']}
          loading="lazy"
        />
      </div>
      {state.showDropdown && (
        <div className={Styles['dropdown-url-name']}>
          {state?.interior360Url?.map((item, index) => (
            <div 
              key={index} 
              className={Styles['dropdown-url-heading']} 
              onClick={() => selectedName(item)}
            >
              {item?.tags?.overlay_name || item?.image_name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InteriorFrameDropdown;
