import React, { Component } from 'react'
import { singleData } from './config'
import Styles from '../../Css/View360/View360.module.css'
import BeforeAfterToggleSvg from './BeforeAfterToggleSvg'

export default class ToggleButton extends Component {
  constructor(props){
    super(props)    
  
  }
  render() {
    if(this.props.beforeAfterView){
      return (
        <div className={[Styles['before-after-tab']]}>
       {this.props.value.showExtOrInt !== "INT" && (
  <ul className={[Styles['before-after-tab-view']]}>
    {singleData?.beforeAfterTabs?.map((elements, indx) => {
      return (
        <li
          key={`iframeTabs-${indx}`}
          onClick={() => this.props.updateState(elements?.key, 'beforeAfter')}
          className={[
            Styles['tabs'],
            elements?.key === this.props.value.activeToggleBeforeAfter
              ? Styles["active"]
              : Styles[""]
          ].join(' ')}
        >
          <BeforeAfterToggleSvg 
            activeTitle={this.props.value.activeToggleBeforeAfter} 
            hotspot={elements?.title === 'Spin 360 only' ? false : true} 
          /> 
          {this.props.value.isAnyHotSpotPresent 
            ? elements.title 
            : `View ${indx + 1}`}
        </li>
      );
    })}
    <div className={[Styles['before-after-tabs-bg']]}></div>
  </ul>
)}

    </div>
      )
    } 

    return (
        <div className={[Styles[this?.props?.mobileView ? 'exterior-interior-toggle-mobile-view' : 'exterior-interior-toggle']]}>
        <ul className={[Styles[this?.props?.mobileView ? 'exterior-interior-tabs-mobile-view' : 'exterior-interior-tabs']]}>
            {singleData?.iframeTabs?.map((elements, indx) => {
               return ((elements?.key === 'UNDERNEATH' && !this.props.value.underneathData?.length > 0) || (elements?.key === 'INT' && !this.props.value.interior360Url.length> 0)) ? null : (
                <li key={`iframeTabs-${indx}`} onClick={()=>this.props.updateState(elements?.key,'exteriorInterior')} className={[Styles['tabs'],elements?.key === this.props.value.showExtOrInt ? Styles["active"] : Styles[""]].join(' ')} >
                  <div className={[Styles[this?.props?.mobileView ? 'ext-tabs-bg-mobile-view' :'ext-tabs-bg']]}>{elements?.title}</div>
                </li>
            )
        })}

            {/* <div className={[Styles[this?.props?.mobileView ? 'ext-tabs-bg-mobile-view' :'ext-tabs-bg']]}></div> */}
        </ul>
    </div>
    )
  }
}
