/**
 * 
 * This page contains reducers
 * 
 */

 import {
    UPDATE_WHOLE_HOTSPOT_MAP_OBJECT
 } from './View360ActionType'


 

 // Initial state
 const initialState = {
    hotSpotMapObj: new Map(), //map to store imagehotspot json,
 }


 /********* IMAGE HOTSPOT JSON ***********/
//  {
//     imgId : '',
//     isHidden: false, //in case image or frame was deleted
//     imageHotSpots: [ // map of hotspots
//         {
//             hotSpotId: '', //skuId_imgId_seqNo,
//             hotSpotIconSrc: '',
//             coordinates: {
//                 xAxis: 0,
//                 yAxis: 0
//             },
//             timeStamp: {
//                 createdOn:'',
//                 updatedOn:''
//             },
//             hotSpotDesc:'',
//             hotSpotTitle:'',
//         }
//     ]

// }

export const view360Reducer = (state=initialState, action)=>{

    switch(action.type){
        case UPDATE_WHOLE_HOTSPOT_MAP_OBJECT : return {
            ...state,
            hotSpotMapObj: new Map(action.payload)
        }

        default : return state
    }

}