import React, { useEffect, useState } from 'react'
import styles from '../../Css/View360/Loader.module.css'

function Loader({showPageName}) {
  const [spinMotion,setSpinMotion] = useState(true)
  useEffect(()=>{
    const queryParams = new URLSearchParams(window.location.search);
    let value = queryParams?.get('spin_motion') || queryParams?.get('version')
    setSpinMotion(value)
  },[])
  return (
    <>
      {spinMotion ? 
      <div className={styles["spinner-container"]}>
        <div className={styles["spinner"]}>
          <div></div>
        </div>
      </div> : 
        <div className={styles['loader-component']}>
        <div>
            <div className={styles['loader']}></div>
            <p>Loading your {showPageName ? showPageName : "360 spin..."}</p>
        </div>

      </div>}
    </>

  )
}

export default Loader