import React from 'react'
import Styles from '../../Css/View360/View360.module.css'
import { singleData } from './config'

const BeforeAfterToggleSvg = (props) => {
    if(props.hotspot)return(
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={Styles['before-after-svg']}>
            <path d="M12.0664 -0.000174643L19.3057 -0.000175276C19.9407 -0.000175332 20.4805 0.222082 20.925 0.666603C21.3695 1.11112 21.5918 1.6509 21.5918 2.28593L21.5918 19.3047C21.5918 19.9397 21.3695 20.4795 20.925 20.924C20.4805 21.3686 19.9407 21.5908 19.3057 21.5908L12.0664 21.5908L12.0664 -0.000174643ZM13.9714 1.90491L13.9714 19.6857L19.3057 19.6857C19.4115 19.6857 19.5013 19.6485 19.5749 19.574C19.6495 19.5003 19.6867 19.4106 19.6867 19.3047L19.6867 2.28593C19.6867 2.18009 19.6495 2.08992 19.5749 2.01541C19.5013 1.94174 19.4115 1.90491 19.3057 1.90491L13.9714 1.90491ZM9.52624 -0.000174421L9.52624 9.52526L0.000800032 9.52526L0.000799399 2.28593C0.000799343 1.6509 0.223057 1.11113 0.667578 0.666605C1.1121 0.222084 1.65187 -0.000173733 2.2869 -0.000173788L9.52624 -0.000174421ZM7.62115 1.90491L2.2869 1.90491C2.18106 1.90491 2.09089 1.94175 2.01638 2.01541C1.94272 2.08992 1.90589 2.18009 1.90589 2.28593L1.90589 7.62018L7.62115 7.62018L7.62115 1.90491Z" fill={props.activeTitle === singleData.beforeAfterTabs[0]?.key?"#4600F2":"#00000066"} />
        </svg>
    )
  return (
       <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={Styles['before-after-svg']}>
       <path d="M11.5917 -0.000178458L18.8311 -0.000179091C19.4661 -0.000179146 20.0059 0.222078 20.4504 0.666599C20.8949 1.11112 21.1172 1.6509 21.1172 2.28593L21.1172 19.3047C21.1172 19.9397 20.8949 20.4795 20.4504 20.924C20.0059 21.3686 19.4661 21.5908 18.8311 21.5908L11.5917 21.5908L11.5917 -0.000178458ZM13.4968 1.90491L13.4968 19.6857L18.8311 19.6857C18.9369 19.6857 19.0267 19.6485 19.1003 19.574C19.1748 19.5003 19.2121 19.4106 19.2121 19.3047L19.2121 2.28593C19.2121 2.18009 19.1748 2.08991 19.1003 2.0154C19.0267 1.94174 18.9369 1.90491 18.8311 1.90491L13.4968 1.90491Z" fill={props.activeTitle === singleData.beforeAfterTabs[1]?.key?"#4600F2":"#00000066"} />
       <path d="M10.1172 -0.000178329L2.87785 -0.000177696C2.24282 -0.000177641 1.70305 0.22208 1.25853 0.666601C0.814007 1.11112 0.591747 1.6509 0.591748 2.28593L0.591745 19.3047C0.591745 19.9397 0.814005 20.4795 1.25853 20.924C1.70305 21.3686 2.24282 21.5908 2.87785 21.5908L10.1172 21.5908L10.1172 -0.000178329ZM8.2121 1.90491L8.2121 19.6857L2.87785 19.6857C2.77201 19.6857 2.68226 19.6485 2.6086 19.574C2.53409 19.5003 2.49683 19.4106 2.49683 19.3047L2.49684 2.28593C2.49684 2.18009 2.53409 2.08991 2.6086 2.01541C2.68226 1.94174 2.77201 1.90491 2.87785 1.90491L8.2121 1.90491Z" fill={props.activeTitle === singleData.beforeAfterTabs[1]?.key?"#4600F2":"#00000066"} />
   </svg>
  )
}

export default BeforeAfterToggleSvg
