import axios from "axios";

export const handleDeleteRequest = (URL, callParams)=>{
    let handleDeletePromise = new Promise((resolve, reject)=>{
        axios({
            method: 'DELETE',
            url: URL,
            params : callParams ? {...callParams} : {},
        }).then(res=>{
            //console.log('res',res);
            if(res.data?.status === 200)
                resolve(res.data);
            else if(res.status === 200)
                resolve(res.data)
            else 
                reject(res.data['message'] || res.data);
            
        }).catch(err=>{
            reject(err);
        })
    });

    return handleDeletePromise;

}

